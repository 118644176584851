import React, { useEffect } from 'react'
import LinkedinIcon from '../assets/images/linkedin-icon.svg'
import FacebookIcon from '../assets/images/facebook-icon.svg'
import YoutubeIcon from '../assets/images/youtube-icon.svg'
import XIcon from '../assets/images/x-icon.png'
import TiktokIcon from '../assets/images/tiktok-icon.svg'
import InstagramIcon from '../assets/images/instagram-icon.svg'
import WebsiteIcon from '../assets/images/website-icon.svg'
import PmIcon from '../assets/images/pm.svg'
import { Button, Col, Form, Input, Row, Select, Space, Typography } from 'antd'
import { STATES } from '../../../../../../utils/us-states-city'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import { CloseOutlined, PlusOutlined, ProjectOutlined } from '@ant-design/icons'
import { addProjectManagementLinkLogo } from '../../../../../../utils/helper'


const { Title, Paragraph, Text } = Typography
const { Option } = Select
const { useForm } = Form

const ItcExtraCompanyDetails = ({handleNextClick, companyDetails, setCompanyDetails, businessLocation, setBusinessLocation, setLocationId}) => {
    const [form] = useForm();
    const tech = useSelector((state) => state.tech)

     const values = Form.useWatch([], form) 
    
    // Check if all required fields are filled
    const isDisabled = !values?.city || !values?.state || !values.zipCode;

    const handleExtraCompanyDetailsSubmit = (values) => {
        ApiBaseHelper.post({
            url: `home`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: {
                city: values?.city,
                state: values?.state,
                zipCode: values?.zipCode,
            }
        }).then((res) => {
            setLocationId(res?.data?.data?.Id)
        }).catch((err) => {
            console.log(err)
        })
        setBusinessLocation({
            ...businessLocation,
            city: values.city,
            state: values.state,
            zip_code: values.zipCode,
        })
        setCompanyDetails({
            ...companyDetails,
            linkedin_url: values.linkedinUrl,
            facebook_url: values.facebookUrl,
            youtube_url: values.youtubeUrl,
            twitter_url: values.twitterUrl,
            tiktok_url: values.tiktokUrl,
            instagram_url: values.instagramUrl,
            website_url: values.websiteUrl,
            project_links: values?.projects?.filter(project => project.projectLinks !== undefined)?.flatMap(member => member.projectLinks)?.join(",") || "",
        })
        handleNextClick()
    }




    useEffect(() => {
        form.setFieldsValue({
            city: businessLocation?.city,
            state: businessLocation?.state,
            zipCode: businessLocation?.zip_code,
            linkedinUrl: companyDetails?.linkedin_url,
            facebookUrl: companyDetails?.facebook_url,
            youtubeUrl: companyDetails?.youtube_url,
            twitterUrl: companyDetails?.twitter_url,
            tiktokUrl: companyDetails?.tiktok_url,
            instagramUrl: companyDetails?.instagram_url,
            websiteUrl: companyDetails?.website_url,
            projects: companyDetails?.project_links ? companyDetails?.project_links.split(",").map(link => ({ projectLinks: link })) : [""],
        })
    }, [])

    return (
        <div className='itc-extra-company-details-section'>
            <div className="itc-extra-company-details-ctn">
                 <div className="itc-extra-details-form-ctn">
                    <Title level={4} className="itc-form-title">Business Location (2/2 steps)</Title>
                    <Form
                        onFinish={handleExtraCompanyDetailsSubmit}
                        form={form}
                        layout='vertical'
                        className="itc-extra-company-details-form"
                    >
                        <BusinessLocationForm form={form} isProject={companyDetails?.is_project}/>
                        <Form.Item  className='itc-company-details-form-item'>
                            <Button className='itc-next-btn' type='primary' htmlType='submit' disabled={isDisabled}>Next</Button>
                        </Form.Item> 
                    </Form>
                </div>
            </div>
        </div>
    )
}

export const BusinessLocationForm = ({form, isProject}) => {
   
    return(
        <>
            <Row gutter={24} className='business-location'>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="city"  
                        label="City" 
                        className='itc-form-label-item'
                        rules={[
                            { required: true, message: 'Please enter your city' },
                        ]}
                    >
                        <Input 
                            type="text" 
                            placeholder="Ex. St. Louis" 
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="state"  
                        label="State" 
                        className='itc-form-label-item'
                        rules={[
                            { required: true, message: 'Please select your state' },
                        ]}  
                    >
                        <Select
                            showSearch
                            placeholder="Select State" 
                            size="large"
                            filterOption={true}
                            optionFilterProp={"label"}
                            options={
                                STATES.map(value => {
                                    return {
                                        label: value.State,
                                        value: value.Code
                                    }
                                })
                            }
                        >
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="zipCode"  
                        label="Zip Code" 
                        className='itc-form-label-item'
                        rules={[
                            {
                                pattern: new RegExp(/^(\d{5})?$/),
                                message: "5 digits only"
                            },
                            {
                                required: true,
                                message: 'Please input your ZIP code',
                            },
                        ]}
                    >
                        <Input 
                            type="text" 
                            placeholder="Ex. 63930" 
                            size="large"
                            maxLength={5}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <div className='social-media-links-ctn'>
                <div className="social-media-links">
                    <Title level={4} className="itc-form-title">Social Media Links</Title>
                    <Form.Item 
                        name='linkedinUrl'
                        rules={[
                        // check if it is a linkedin url
                        { pattern: /^(https?:\/\/)?(www\.)?linkedin\.com\/(in\/|company\/)[A-Za-z0-9_-]+\/?$/
                        , message: 'Please enter a valid Linkedin URL' }
                        ]}
                    >
                        <Input
                            type="text"
                            placeholder="Linkedin Business URL"
                            size="large"
                            addonBefore={<img src={LinkedinIcon} alt="linkedin icon" />}
                        />
                    </Form.Item>
                    <Form.Item
                        name='facebookUrl'
                        rules={[
                        // check if it is a facebook url
                        { pattern: /^(https?:\/\/)?(www\.|en-gb\.|m\.)?facebook\.com\/(profile\.php\?id=\d+|[A-Za-z0-9_.]+)\/?$/, message: 'Please enter a valid Facebook URL' }
                        ]}
                    >
                        <Input
                            type="text"
                            placeholder="Facebook Business URL"
                            size="large"
                            addonBefore={<img src={FacebookIcon} alt="facebook icon" />}
                        />
                    </Form.Item>
                    <Form.Item
                        name='youtubeUrl'
                        rules={[
                        // check if it is a youtube url
                        { pattern: /^(https?:\/\/)?(www\.|m\.)?youtube\.com\/(channel\/|user\/|c\/|@)?[A-Za-z0-9_-]+$/, message: 'Please enter a valid Youtube URL' }
                        ]}
                    >
                        <Input
                            type="text"
                            placeholder="Youtube Business URL"
                            size="large"
                            addonBefore={<img src={YoutubeIcon} alt="youtube icon" />}
                        />
                    </Form.Item>
                    <Form.Item
                        name='twitterUrl'
                        rules={[
                        // check if it is a twitter url
                        { pattern: /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/([A-Za-z0-9_]{1,15})$/, message: 'Please enter a valid Twitter URL' }
                        ]}
                    >
                        <Input
                            type="text"
                            placeholder="X(Twitter) Business URL"
                            size="large"
                            addonBefore={<img src={XIcon} width={20} alt="x icon" />}
                        />
                    </Form.Item>
                    <Form.Item
                        name="tiktokUrl"
                        rules={[
                        // check if it is a tiktok url
                        { pattern: /^(https?:\/\/)?(www\.)?tiktok\.com\/@?[A-Za-z0-9_-]+/, message: 'Please enter a valid Tiktok URL' }
                        ]}
                    >
                        <Input
                            type="text"
                            placeholder="Tiktok Business URL"
                            size="large"
                            addonBefore={<img src={TiktokIcon} alt="tiktok icon" />}
                        />
                    </Form.Item>
                    <Form.Item 
                        name="instagramUrl"
                        rules={[
                        // check if it is a instagram url
                        { pattern: /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9_-]+/, message: 'Please enter a valid Instagram URL' }
                        ]}
                    >
                        <Input
                            type="text"
                            placeholder="Instagram Business URL"
                            size="large"
                            addonBefore={<img src={InstagramIcon} alt="instagram icon" />}
                        />
                    </Form.Item>
                    <Form.Item
                        name="websiteUrl"
                        rules={[
                        // check if it is a valid url
                        { pattern: /^(https?:\/\/)?(www\.)?[A-Za-z0-9_-]+\.[A-Za-z0-9_-]+/, message: 'Please enter a valid URL' }
                        ]}
                    >
                        <Input
                            type="text"
                            placeholder="Business Website URL"
                            size="large"
                            addonBefore={<img src={WebsiteIcon} alt="website icon" style={{width:21}} />}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className='pjt-mngt-links-ctn'>
                <div className="pjt-mngt-links">
                    <Title level={4} className="itc-form-title mb-0">Project Management Links</Title>
                    <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>
                        Add external links used to manage your {isProject ? 'Project' : 'ITSC'} (e.g., Trello, Asana, Jira, Notion, etc.). These links will only be visible to your team members on the dashboard.
                    </Paragraph> 
                    <Form.List name="projects" initialValue={[{ projectLinks: '' }]} >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Row key={field.key} gutter={[18, 14]} justify={"space-between"} align={"middle"} className='member-form-row'>
                                        <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'projectLinks']}
                                                fieldKey={[field.fieldKey, 'projectLinks']} 
                                            >
                                                
                                                <Input 
                                                    className='member-form-input' 
                                                    size='large' 
                                                    // prefix={<ProjectOutlined />} 
                                                    addonBefore={<img src={addProjectManagementLinkLogo(form.getFieldValue(['projects', index, 'projectLinks']), "logo") || PmIcon}
                                                    alt={addProjectManagementLinkLogo(form.getFieldValue(['projects', index, 'projectLinks']), "name")} style={{width:21}} />}
                                                    placeholder='Enter project management link' 
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                                            <CloseOutlined onClick={
                                                () => {
                                                    if(fields.length === 1){
                                                        form.setFieldsValue({projects: [{ projectLinks: '' }]})
                                                        return
                                                    }
                                                    remove(field.name)
                                                }
                                            } className='input-close-icon'/>
                                        </Col>  
                                        <Col xs={24} sm={24} md={0} lg={0} xl={0}>
                                            <Paragraph 
                                                onClick={() => { 
                                                    if(fields.length === 1){
                                                        form.setFieldsValue({projects: [{ projectLinks: '' }]})
                                                        return
                                                    }
                                                    remove(field.name)
                                                }} 
                                                className='input-close-icon'
                                            >
                                                Remove
                                            </Paragraph>
                                        </Col> 
                                    </Row> 
                                ))}
                                <Row align={"middle"} justify={"space-between"} className='member-form-row cta-form-row'>
                                    <Col xs={12} sm={12} md={13} lg={13} xl={13}>
                                        <Space
                                            onClick={() => add()}
                                            style={{cursor:"pointer"}}>
                                            <PlusOutlined />
                                            <Text>Add Another</Text>
                                        </Space>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form.List>
                </div>
            </div>
        </>
    )
}

export default ItcExtraCompanyDetails