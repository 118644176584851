import {message, Modal, notification, Typography} from 'antd';
import {FirebaseHelper, FirestoreHelper} from "./firebase-helper";
import ROUTES from "./routes";
import {ApiBaseHelper, BASE_URL} from "./api-base-helper";
import {updateAdmin, updateTech, updateTechReserve, updateUser} from "../redux/reducers/app";
import CryptoJS from 'crypto-js';
import moment from "moment";
// import {encode} from 'html-entities';
import {ACCEPTANCE_SERVER, DEV_SERVER, MINIMUM_NUMBER_OF_TECHS, PROD_SERVER, STAGING_SERVER} from "./constants";
import history from './history';
// import citiesStatesWithLngLat from "./cities-states-with-lng-lat.json"
import citiesStatesWithLngLatMin from "./cities-states-with-lng-lat-min.json"
import { STATES, MATCHED_CITIES_STATES } from './us-states-city';
import { ManagerRouteBlacklist, RecruiterRouteBlacklist } from './route-blacklist';
import MasterCardSvg from "../assets/images/master-card.svg";
import VisaSvg from "../assets/images/visa.svg";
import UnionPaySvg from "../assets/images/union-pay.svg";
import JcbSvg from "../assets/images/jcb.svg";
import DiscoverSvg from "../assets/images/discover.svg";
import DinersClubSvg from "../assets/images/diners-club.svg";
import AmericanExpressSvg from "../assets/images/american-express.svg";
import { RtmRepository } from '../views/pages/common/private/real-time-messaging/repository/rtm-repository';
import { AggregateQuerySnapshot } from 'firebase/firestore';
import JiraIcon from '../assets/images/jira-icon.svg';
import AsanaIcon from '../assets/images/asana-icon.svg';
import TrelloIcon from '../assets/images/trello-icon.svg';
import ClickupIcon from '../assets/images/clickup-icon.svg';
import NotionIcon from '../assets/images/notion-icon.svg';
import GenericIcon from '../assets/images/generic-icon.svg';

// helper functions

export const nameFormat = (name) => {
    if(!name) return ""
    if (!name.includes(" ")) {
        return name
    }
    let splitName = name.split(" ")
    return splitName[0] + " " + splitName[1][0] + "."
}

export const altNameFormat = (name) => {
    if(!name) return ""
    if (!name.includes(" ")) {
        return name
    }
    let splitName = name.split(" ")
    return splitName[1][0]?.toUpperCase() + ". " + capitalizeFirstLetter(splitName[0]?.toLowerCase()) 
}

export const DEFAULT_DEVICE_TYPES = [
    {
        "Id": "a1W55000001Y3oLEAS",
        "Group_Name": "Desktop",
        "Pic": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a1W55000001Y3oLEAS-php1nhUcI_x_y.png",
        "Category_Id": 1,
        "Operating_System": [
            {
                "Picture": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a0C55000003lo95EAA-windows_os.png",
                "Name": "Windows"
            },
            {
                "Picture": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a0C55000003lo95EAA-phpK2zkT7_x_y.png",
                "Name": "macOS"
            },
            {
                "Picture": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a0C55000003lo95EAA-phpE8OWs7_x_y.png",
                "Name": "Chromebook"
            }
        ]
    },
    {
        "Id": "a1W55000001WZoIEAW",
        "Group_Name": "Tablet",
        "Pic": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a1W55000001WZoIEAW-phpIDWMQH_x_y.png",
        "Category_Id": 2,
        "Operating_System": [
            {
                "Picture": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a0C55000003lo95EAA-phpaSijkC_x_y.png",
                "Name": "iOS"
            },
            {
                "Picture": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a0C55000003lo95EAA-php7xyud7_x_y.webp",
                "Name": "Android"
            },
            {
                "Picture": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a0C55000003lo95EAA-phpE8OWs7_x_y.png",
                "Name": "Chromebook"
            }
        ]
    },
    {
        "Id": "a1W55000001WZoDEAW",
        "Group_Name": "Phone",
        "Pic": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a1W55000001WZoDEAW-phpyC0jqf_x_y.png",
        "Category_Id": 3,
        "Operating_System": [
            {
                "Picture": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a0C55000003lo95EAA-phpaSijkC_x_y.png",
                "Name": "iOS"
            },
            {
                "Picture": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a0C55000003lo95EAA-php7xyud7_x_y.webp",
                "Name": "Android"
            }
        ]
    },
    {
        "Id": "a1W55000001WZo3EAG",
        "Group_Name": "TV",
        "Pic": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a1W55000001WZo3EAG-phpEuncfC_x_y.png",
        "Category_Id": 4,
        "Operating_System": null
    },
    {
        "Id": "a1W55000001WZoNEAW",
        "Group_Name": "Accessory",
        "Pic": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a1W55000001WZoNEAW-phpnfc95O_x_y.png",
        "Category_Id": 5,
        "Operating_System": null
    },
    {
        "Id": "a1W55000001WZntEAG",
        "Group_Name": "Laptop",
        "Pic": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a1W55000001WZntEAG-phpTA9wtN_x_y.png",
        "Category_Id": 6,
        "Operating_System": [
            {
                "Picture": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a0C55000003lo95EAA-windows_os.png",
                "Name": "Windows"
            },
            {
                "Picture": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a0C55000003lo95EAA-phpK2zkT7_x_y.png",
                "Name": "macOS"
            },
            {
                "Picture": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a0C55000003lo95EAA-phpE8OWs7_x_y.png",
                "Name": "Chromebook"
            }
        ]
    },
    {
        "Id": "a1W55000001WZnyEAG",
        "Group_Name": "Wifi",
        "Pic": "https://s3-us-west-2.amazonaws.com/hipaadev/uploaded/a1W55000001WZnyEAG-phpUZBA4P_x_y.png",
        "Category_Id": 7,
        "Operating_System": null
    }
];
export const SERVICE_IMAGE_LIBRARY = [
    {
        title: "Mobile Phones",
        images: [
            {
                uid: 'mobile-1',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1612442443556-09b5b309e637?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YW5kcm9pZCUyMHBob25lfGVufDB8MHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
            },
            {
                uid: 'mobile-2',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1480694313141-fce5e697ee25?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8c21hcnRwaG9uZXxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
            },
            {
                uid: 'mobile-3',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1603921326210-6edd2d60ca68?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8c2Ftc3VuZyUyMHBob25lfGVufDB8MHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
            },
            {
                uid: 'mobile-4',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1556656793-08538906a9f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aXBob25lfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=500&q=60'
            },
            {
                uid: 'mobile-5',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1512499617640-c74ae3a79d37?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aXBob25lfGVufDB8MHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
            },
            {
                uid: 'mobile-6',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1519923834699-ef0b7cde4712?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8aXBob25lfGVufDB8MHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
            },
            {
                uid: 'mobile-7',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1610945265064-0e34e5519bbf?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2Ftc3VuZyUyMHBob25lfGVufDB8MHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
            },
            {
                uid: 'mobile-8',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1568378711447-f5eef04d85b5?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHNhbXN1bmclMjBwaG9uZXxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
            },


        ]
    },
    {
        title: "Laptop",
        images: [
            {
                uid: 'Laptop-1',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1593642702909-dec73df255d7?fit=crop&w=450&q=80',
            },
            {
                uid: 'Laptop-2',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1516387938699-a93567ec168e?fit=crop&w=450&q=80',
            },
            {
                uid: 'Laptop-3',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1603302576837-37561b2e2302?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGxhcHRvcHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Laptop-4',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1559163499-413811fb2344?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGxhcHRvcHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Laptop-5',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1485988412941-77a35537dae4?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzF8fGxhcHRvcHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Laptop-6',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1511385348-a52b4a160dc2?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzR8fGxhcHRvcHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Laptop-7',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1602080858428-57174f9431cf?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fGxhcHRvcHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },

        ]
    },
    {
        title: "Desktop",
        images: [
            {
                uid: 'Desktop-1',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1542435503-956c469947f6?fit=crop&w=450&q=80',
            },
            {
                uid: 'Desktop-2',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1529336953128-a85760f58cb5?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8ZGVza3RvcHxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Desktop-3',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1594400344473-cf8b48733c1f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGRlc2t0b3B8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Desktop-4',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1614624532983-4ce03382d63d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fGRlc2t0b3B8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Desktop-5',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1593640495390-1ff7c3f60e9b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGRlc2t0b3B8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Desktop-6',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1493723843671-1d655e66ac1c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjV8fGRlc2t0b3B8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Desktop-7',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1593300806914-e1884ba37524?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzV8fGRlc2t0b3B8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Desktop-8',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1587831990711-23ca6441447b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDR8fGRlc2t0b3B8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },

        ]
    },
    {
        title: "Televisions",
        images: [
            {
                uid: 'TV-1',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1593784991095-a205069470b6?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dHZ8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'TV-2',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1461151304267-38535e780c79?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dGVsZXZpc2lvbnxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'TV-3',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1586024486164-ce9b3d87e09f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dGVsZXZpc2lvbnxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'TV-4',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1593359677879-a4bb92f829d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8dGVsZXZpc2lvbnxlbnwwfDB8MHx8&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'TV-5',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1601944177325-f8867652837f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fHRlbGV2aXNpb258ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'TV-6',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1593078165899-c7d2ac0d6aea?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjJ8fHRlbGV2aXNpb258ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'TV-7',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1601944179066-29786cb9d32a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fHRlbGV2aXNpb258ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },

        ],
    },
    {
        title: "Printers",
        images: [
            {
                uid: 'Printer-1',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1612815154858-60aa4c59eaa6?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cHJpbnRlcnxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Printer-2',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1625961332771-3f40b0e2bdcf?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8cHJpbnRlcnxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Printer-3',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1551781069-73a1da89e333?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cHJpbnRlcnxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Printer-4',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1571845995697-28be270350de?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8cHJpbnRlcnxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Printer-5',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1523120974498-9d764390d8e5?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fHByaW50ZXJ8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Printer-6',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1630327722923-5ebd594ddda9?ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODN8fHByaW50ZXJ8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },


        ],
    },
    {
        title: "Home Theaters",
        images: [
            {
                uid: 'Theater-1',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1596793503406-d90c450ba1b1?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aG9tZSUyMHRoZWF0ZXJ8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Theater-2',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/flagged/photo-1572609239482-d3a83f976aa0?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aG9tZSUyMHRoZWF0ZXJ8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Theater-3',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1540407473297-b9c7ef744e1a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG9tZSUyMHRoZWF0ZXJ8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Theater-4',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://www.lifewire.com/thmb/ndpZEPjdTCLntZQwkBPH4eZ4oKs=/3865x2577/filters:no_upscale():max_bytes(150000):strip_icc()/movie-playing-on-projection-screen-in-home-theater-915093896-5bdb7eb0c9e77c0026d2970f-52f2860826054782a4fc48b588e441fe.jpg',
            },
            {
                uid: 'Theater-5',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://avidiaonline.com/images/easyblog_articles/18/4-Steps-to-Designing-the-Ultimate-Home-Theater-System_69d101d0e9184e3897dd4c23a1c89d1e.jpg',
            },
            {
                uid: 'Theater-6',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1532621623813-d37d9cd1d992?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8aG9tZSUyMHRoZWF0ZXJ8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Theater-7',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1589113050289-1c654e7e305d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aG9tZSUyMGNpbmVtYXxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Theater-8',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1489599849927-2ee91cede3ba?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y2luZW1hfGVufDB8MHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
        ],
    },
    {
        title: "Consoles",
        images: [
            {
                uid: 'Console-1',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1605901309584-818e25960a8f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y29uc29sZXN8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Console-2',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1550921464-9f7a27f99edc?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y29uc29sZXN8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Console-3',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1531594896955-305cf81269f1?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fGNvbnNvbGVzfGVufDB8MHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Console-4',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1507457379470-08b800bebc67?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGxheXN0YXRpb258ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Console-5',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1606144042614-b2417e99c4e3?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGxheXN0YXRpb258ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Console-6',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1614294148960-9aa740632a87?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8eGJveHxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'Console-7',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1603565913235-382c62d3b2ee?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHhib3h8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },

        ],
    },
    {
        title: "Wifi",
        images: [
            {
                uid: 'wifi-1',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1516044734145-07ca8eef8731?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8d2lmaXxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'wifi-2',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1603731955926-81917dad5a80?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8d2lmaXxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'wifi-3',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1606904825846-647eb07f5be2?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8d2lmaXxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'wifi-4',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1554098415-cae1af5e4f1a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8d2lmaXxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'wifi-5',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1606421753414-8d165c9d48e5?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fHdpZml8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'wifi-6',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1554098415-4052459dc340?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fHdpZml8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },
            {
                uid: 'wifi-7',
                percent: 100,
                name: 'image.png',
                status: 'done',
                url: 'https://images.unsplash.com/photo-1603899123005-4b772bef4f09?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8d2lmaXxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
            },

        ],
    },


    // {
    //     uid: 'mobile-',
    //     percent: 100,
    //     name: 'image.png',
    //     status: 'done',
    //     url:'VALUEE'
    // },

];
export const SERVICES_SUGGESTIONS = [
    {
        title: "Computer Maintenance and Cleaning",
        description: "Interior cleaning of a desktop or laptop, re-application of thermal paste if needed and ensuring proper air flow is achieved.",
        deliverables: [
            "Visibly inspect the inside of desktop/laptop looking for dust build up",
            "In older desktops, reapply thermal paste between the CPU and heat sink because it gets dry and brittle over time",
            "Use laser-eye disc cleaner to rinse your disk drive's optical eye",
            "Ensure the PC has proper air flow and clean fans to be able to dissipate heat properly",
        ],
        location: "on_site",
        deviceTypes: "Desktop,Laptop",
        active: false,
        price: null,
        usageType: "Metered",
        "image": "desktop.jpeg",
        file: 'https://images.unsplash.com/photo-1542435503-956c469947f6?fit=crop&w=450&q=80',

    },
    {
        title: "TV Setup",
        description: "Setup new and used TVs.",
        deliverables: [
            "Connect all necessary video components and cables",
            "Configure a sound bar if needed",
            "Set up of necessary peripherals",
            "Configure an existing wireless network",
        ],
        location: "on_site",
        deviceTypes: "TV",
        active: false,
        price: null,
        usageType: "Metered",
        "image": "television-support.jpeg",
        file: 'https://images.unsplash.com/photo-1461151304267-38535e780c79?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dGVsZXZpc2lvbnxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    },
    {
        title: "Password Recovery and Reset",
        description: "Help reset or recover password and gain access to your account.",
        deliverables: [
            "Assist in resetting or recovering password.",
        ],
        location: "on_site",
        // deviceTypes: "Desktop,Laptop,Phone,Tablet",
        deviceTypes: null,
        active: false,
        price: null,
        usageType: "Metered",
        image: "security-checkup.jpg",
        file: 'https://images.unsplash.com/photo-1593642702909-dec73df255d7?fit=crop&w=450&q=80',

    },
    {
        title: "Mobile Device Setup (android and iOS)",
        description: "Setup new and used Mobiles",
        deliverables: [
            "Set up a phone, ensuring it's connected to Wi-Fi",
            "Configure an email account on the device",
            "Setup google play or app store",
            "Configure basic settings on the device",
            "Download and install all required apps",
        ],
        location: "on_site",
        deviceTypes: "Phone,Tablet",
        active: false,
        price: null,
        usageType: "Metered",
        image: "mobile-device-setup.jpg",
        file: 'https://images.unsplash.com/photo-1612442443556-09b5b309e637?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YW5kcm9pZCUyMHBob25lfGVufDB8MHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'

    },
    {
        title: "Printer Setup",
        description: "Setup and install Printers",
        deliverables: [
            "Configure and test new printer",
            "Ensuring it has ink cartridges inserted correctly",
            "Connect it to Wi-Fi network or any other devices.",
            "Demonstrate basic printer functionalities and its working",
        ],
        location: "on_site",
        deviceTypes: "Printer",
        active: false,
        price: null,
        usageType: "Metered",
        image: "printer-setup.jpeg",
        file: 'https://images.unsplash.com/photo-1612815154858-60aa4c59eaa6?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cHJpbnRlcnxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    },
    {
        title: "Computer Security checkup- Virus Removal and Tune Up",
        description: "Scan computer viruses and clean up OS for better performance",
        deliverables: [
            "Perform a full scan for viruses, spyware, and malware and remove",
            "Install supplied antivirus if needed",
            "Removal of temp files",
            "Uninstall unneeded and or unwanted computer programs",
            "Fix computer errors",
            "Clean up the registry",
        ],
        location: "on_site",
        deviceTypes: "Desktops, Laptops",
        active: false,
        price: null,
        usageType: "Metered",
        image: "computer-refresh.jpg",
        file: 'https://images.unsplash.com/photo-1493723843671-1d655e66ac1c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjV8fGRlc2t0b3B8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    },
    {
        title: "Data Backup or Transfer",
        description: "Back up all personals files or transfer to new computer",
        deliverables: [
            "Backup data to a CD or DV. ",
            "Backup data to an external hard drive",
            "Backup data to the cloud",
            "Transfer files to new PC",
        ],
        location: "on_site",
        deviceTypes: "Desktops,Laptops,Phone,Tablet",
        active: false,
        price: null,
        usageType: "Metered",
        image: "data-backup.jpeg",
        file: 'https://images.unsplash.com/photo-1587831990711-23ca6441447b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDR8fGRlc2t0b3B8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    },
    {
        title: "Home Theater Setup",
        description: "Ensuring home theater audio & video device connections",
        deliverables: [
            "Assess installation area",
            "Connect audio and video equipment in one room",
            "Connect any capable devices to an existing wireless network",
            "Connect other devices if necessary",
            "Give a walk-through of the new system",
        ],
        location: "on_site",
        deviceTypes: "TV",
        active: false,
        price: null,
        usageType: "Metered",
        image: "home-theater.jpeg",
        file: 'https://images.unsplash.com/flagged/photo-1572609239482-d3a83f976aa0?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aG9tZSUyMHRoZWF0ZXJ8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    },
    {
        title: "Email or Online Account Setup Support",
        description: "Setup new email accounts and fix email issues",
        deliverables: [
            "Setup active or new email account",
            "Configure account settings and features",
            "Demonstrate email functionalities",
            "Troubleshoot Account issues",
        ],
        location: "on_site",
        // deviceTypes: "Laptops, Desktops, Mobiles, Tablets",
        deviceTypes: null,
        active: false,
        price: null,
        usageType: "Metered",
        image: "email-setup.jpeg",
        file: "https://images.unsplash.com/photo-1603791440384-56cd371ee9a7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=618&q=80"

    },
    {
        title: "New Wi-Fi Setup",
        description: "Setup new Wi-Fi device",
        deliverables: [
            "Install and configure Wi-Fi",
            "Setup custom password if required",
            "Connect all devices to new Wi-Fi ",
        ],
        location: "on_site",
        deviceTypes: "WiFi",
        active: false,
        price: null,
        usageType: "Metered",
        image: "wifi-setup.jpeg",
        file: 'https://images.unsplash.com/photo-1603731955926-81917dad5a80?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8d2lmaXxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    },
    {
        title: "Computer Refresh",
        description: "Cleaning up old files and making sure the device is running at its optimum speed",
        deliverables: [
            "Check all power, system, and boot settings to ensure the best performance.",
            "Perform a disk clean-up",
            "Check and make sure windows is up-to-date.  ",
            "Recommend and install some good anti-virus software for extra protection.",
        ],
        location: "on_site",
        deviceTypes: "Desktop,Laptop",
        active: false,
        price: null,
        usageType: "Metered",
        image: "computer-setup.jpg",
        file: 'https://images.unsplash.com/photo-1614624532983-4ce03382d63d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fGRlc2t0b3B8ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    },
    {
        title: "TV Repair",
        description: "Check if a TV is repairable before disposing",
        deliverables: [
            "Check the most common reasons a TV would go out (capacitors, power board, etc)",
            "Replace the damaged parts with the new ones",
            "Do basic TV settings like brightness and contrast",
            "Tune up for latest channels",
        ],
        location: "on_site",
        deviceTypes: "TV",
        active: false,
        price: null,
        usageType: "Metered",
        image: "television-setup.jpeg",
        file: 'https://images.unsplash.com/photo-1601944179066-29786cb9d32a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fHRlbGV2aXNpb258ZW58MHwwfDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    },
    {
        title: "Streaming Device Setup and Support",
        description: "Help setup your new streaming device like Apple TV, Chromecast, Roku or Amazon Fire TV and more.",
        deliverables: [
            "Connect a streaming device to your TV and run the setup",
            "Install streaming video apps",
            "Connect the device to available internet resource",
            "Demonstrate the full functionality",
        ],
        location: "on_site",
        deviceTypes: "TV",
        active: false,
        price: null,
        usageType: "Metered",
        image: "streaming-device.jpeg",
        file: 'https://images.unsplash.com/photo-1615986201152-7686a4867f30?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=625&q=80'

    },
    {
        title: "Wi-Fi security settings and optimization",
        description: "Update your router with the latest security features",
        deliverables: [
            "Configure and secure a router",
            "Ensure a strong and secured password",
            "Troubleshoot connectivity issues and optimize Wi-Fi networks for better performance",
        ],
        location: "on_site",
        deviceTypes: "WiFi",
        active: false,
        price: null,
        usageType: "Metered",
        image: "wifi-optimize.jpeg",
        file: 'https://images.unsplash.com/photo-1606904825846-647eb07f5be2?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8d2lmaXxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    },
    {
        title: "Technology Recycling",
        description: "Take the dead electronic items to the Recycler.",
        deliverables: [
            "Recycling technologies and use waste materials to make new products. Used or damaged parts can be used in making refurbished devices and batteries.",
        ],
        location: "on_site",
        deviceTypes: null,
        active: false,
        price: null,
        usageType: "Metered",
        image: "technology-recycling.png",
        file: 'https://images.unsplash.com/photo-1518770660439-4636190af475?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'

    },
    {
        title: "Mobile Device Repair",
        description: "Help solve common mobile device problems, so you can stay connected.",
        deliverables: [
            "Have your cracked or damaged phone screen replaced",
            "Clean your charging port and remove anything that is obstructing the connection.",
            "Ensure your device is functioning properly",
        ],
        location: "on_site",
        deviceTypes: "Phone,Tablet",
        active: false,
        price: null,
        usageType: "Metered",
        image: "screen-repair.jpeg",
        file: 'https://images.unsplash.com/photo-1480694313141-fce5e697ee25?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8c21hcnRwaG9uZXxlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'

    },
];
export const BRANDS = [
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile', 'desktop', 'laptop', 'accessory', 'tv', 'printer'],
        image: 'https://cdn0.iconfinder.com/data/icons/logos-21/40/Samsung-128.png',
        option: 'Samsung'
    },
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile', 'desktop', 'laptop', 'accessory'],
        image: 'https://cdn3.iconfinder.com/data/icons/logos-brands-3/24/logo_brand_brands_logos_apple_ios-128.png',
        option: 'Apple'
    },
    {
        key: ['phone', 'tablet', 'desktop', 'laptop'],
        image: 'https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/78-microsoft-128.png',
        option: 'Microsoft'
    },
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile', 'desktop', 'laptop', 'accessory'],
        image: 'https://cdn1.iconfinder.com/data/icons/metro-ui-dock-icon-set--icons-by-dakirby/128/Lenovo.png',
        option: 'Lenovo'
    },
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile', 'desktop', 'laptop', 'accessory', 'wifi'],
        image: 'https://cdn1.iconfinder.com/data/icons/metro-ui-dock-icon-set--icons-by-dakirby/128/Asus.png',
        option: 'ASUS'
    },
    {
        key: ['desktop', 'laptop', 'tablet', 'printer'],
        image: 'https://cdn1.iconfinder.com/data/icons/metro-ui-dock-icon-set--icons-by-dakirby/128/Dell_alt.png',
        option: 'DELL'
    },
    {
        key: ['desktop', 'laptop', 'accessory', 'printer', 'vr', 'virtual reality', 'tablet', 'vr headset'],
        image: 'https://cdn1.iconfinder.com/data/icons/metro-ui-dock-icon-set--icons-by-dakirby/128/HP.png',
        option: 'HP'
    },
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile'],
        image: 'https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/xiaomi-128.png',
        option: 'Xiaomi'
    },
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile', 'accessory', 'wifi'],
        image: 'https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Google-128.png',
        option: 'Google'
    },
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile', 'desktop', 'laptop', 'smart watch'],
        image: 'https://cdn4.iconfinder.com/data/icons/logos-brands-5/24/huawei-128.png',
        option: 'Huawei'
    },
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile'],
        image: 'https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/nokia-128.png',
        option: 'Nokia'
    },
    {
        key: ['phone', 'smartphone', 'smart phone', 'mobile', 'accessory', 'desktop', 'laptop', 'tv', 'smart watch'],
        image: 'https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/sony-128.png',
        option: 'Sony'
    },
    {
        key: ['desktop', 'laptop'],
        image: 'https://cdn1.iconfinder.com/data/icons/metro-ui-dock-icon-set--icons-by-dakirby/128/Acer.png',
        option: 'Acer'
    },
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile', 'accessory', 'tv', 'desktop', 'laptop'],
        image: 'https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/lg-128.png',
        option: 'LG'
    },
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile', 'smart watch'],
        image: 'https://cdn0.iconfinder.com/data/icons/Puck_Icons_Pack_II_by_deleket/128/Motorola.png',
        option: 'Motorola'
    },
    {
        key: ['game system', 'game', 'gaming console', 'gaming', 'console', 'gaming system'],
        image: 'https://cdn0.iconfinder.com/data/icons/social-flat-buttons-3/512/xbox-128.png',
        option: 'Xbox'
    },
    {
        key: ['game system', 'game', 'gaming console', 'gaming', 'console', 'gaming system'],
        image: 'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/playstation-128.png',
        option: 'Play Station'
    },
    {
        key: ['game system', 'game', 'gaming console', 'gaming', 'console', 'gaming system'],
        image: 'https://cdn1.iconfinder.com/data/icons/game-asset-2/24/nitendo_switch-128.png',
        option: 'Nitendo Switch'
    },
    {
        key: ['game system', 'game', 'gaming console', 'gaming', 'console', 'gaming system'],
        image: 'https://cdn1.iconfinder.com/data/icons/game-asset-2/24/nitendo_switch-128.png',
        option: 'Nitendo Wii'
    },
    {
        key: ['vr', 'vr headset', 'virtual reality'],
        image: 'https://cdn2.iconfinder.com/data/icons/css-vol-2/24/oculus-128.png',
        option: 'Oculus'
    },
    {
        key: ['vr', 'vr headset', 'virtual reality', 'phone', 'smartphone', 'smart phone', 'mobile'],
        image: 'https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/htc-128.png',
        option: 'HTC Vive'
    },
    {
        key: ['desktop', 'laptop'],
        image: 'https://pbs.twimg.com/profile_images/1346135292432982018/lQqtg6qy_400x400.png',
        option: 'iBUYPOWER'
    },
    {
        key: ['desktop', 'laptop'],
        image: 'https://cdn1.iconfinder.com/data/icons/_dock_icons___by_adrenn/128/alienware.png',
        option: 'Alienware'
    },
    {
        key: ['desktop'],
        image: 'https://www.pngitem.com/pimgs/m/592-5924082_cyberpower-pc-logo-hd-png-download.png',
        option: 'CyberPowerPC'
    },
    {
        key: ['desktop', 'laptop'],
        image: 'https://logosvector.net/wp-content/uploads/2021/08/msi-logo-symbol.png',
        option: 'MSI'
    },
    {
        key: ['desktop', 'laptop', 'printer'],
        image: 'https://cdn.iconscout.com/icon/free/png-256/toshiba-1-282829.png',
        option: 'Toshiba'
    },
    {
        key: ['laptop'],
        image: 'https://www.thegamingeconomy.exchangewire.com/files/2020/01/Razer-logo.png',
        option: 'Razer'
    },
    {
        key: ['laptop', 'printer'],
        image: 'https://i0.wp.com/www.fusionpr.com/wp-content/uploads/2017/02/Fujitsu-Logo.png?ssl=1',
        option: 'Fujitsu'
    },
    {
        key: ['laptop'],
        image: 'https://e7.pngegg.com/pngimages/458/837/png-clipart-logo-gigabyte-technology-organization-font-docx-resume-blue-text.png',
        option: 'GIGA BYTE'
    },
    {
        key: ['laptop'],
        image: 'https://cf.shopee.com.my/file/0956f9cfd0668401c4a3e2958c9260a1',
        option: 'BMAX'
    },
    {
        key: ['laptop'],
        image: 'https://united-comm.com/wp-content/uploads/2011/02/Infinix-logo.png',
        option: 'Infinix'
    },
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile'],
        image: 'https://wallpaperaccess.com/full/4392106.jpg',
        option: 'Oppo'
    },
    {
        key: ['phone', 'tablet', 'smartphone', 'smart phone', 'mobile'],
        image: 'http://ocdn.eu/images/pulscms/Mjc7MDA_/d88897b371dffe00079b24122922aa79.jpg',
        option: 'Vivo'
    },
    {
        key: ['tablet'],
        image: 'https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/amazon-128.png',
        option: 'Amazon'
    },
    {
        key: ['tablet'],
        image: 'https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/nvidia-128.png',
        option: 'Nvidia'
    },
    {
        key: ['tv'],
        image: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Skyworth_logo_2.jpg',
        option: 'Skyworth'
    },
    {
        key: ['tv'],
        image: 'https://logowik.com/content/uploads/images/hisense2847.jpg',
        option: 'Hisense'
    },
    {
        key: ['tv'],
        image: 'https://logicoolair.com.au/wp-content/uploads/2020/08/Panasonic_logo.jpg',
        option: 'Panasonic'
    },
    {
        key: ['tv'],
        image: 'https://www.vizio.com/content/dam/vizio/2021/global/logo-social-preview.jpg',
        option: 'Vizio'
    },
    {
        key: ['tv'],
        image: 'https://cdn.iconscout.com/icon/free/png-256/philips-282137.png',
        option: 'Philips'
    },
    {
        key: ['tv'],
        image: 'https://cdn.iconscout.com/icon/free/png-256/hitachi-283255.png',
        option: 'Hitachi'
    },
    {
        key: ['wifi'],
        image: 'https://companiesmarketcap.com/img/company-logos/256/NTGR.png',
        option: 'Netgear'
    },
    {
        key: ['wifi'],
        image: 'https://b.thumbs.redditmedia.com/Z910Q3MzFmW6HE769fPrczdVHx0S7wmZaMCmXz_6TUA.png',
        option: 'Tp-Link'
    },
    {
        key: ['wifi'],
        image: 'https://www.netspotapp.com/images/upload/icons/Linksys@2x.png',
        option: 'Linksys'
    },
    {
        key: ['wifi'],
        image: 'http://damienjule.com/wp-content/uploads/2020/09/logo-synology-square.png',
        option: 'Synology'
    },
    {
        key: ['printer'],
        image: 'https://cdn.iconscout.com/icon/free/png-256/canon-1863563-1579771.png',
        option: 'Canon'
    },
    {
        key: ['printer'],
        image: 'https://cdn.iconscout.com/icon/free/png-256/epson-283249.png',
        option: 'Epson'
    },
    {
        key: ['printer'],
        image: 'https://static.netify.ai/logos/b/r/o/oebgure/icon.png?v=2',
        option: 'Brother'
    },
    {
        key: ['printer'],
        image: 'https://www.westcoast.co.uk/Images/Product/Default/medium/lexmark-logo.jpg',
        option: 'Lexmark'
    },
    {
        key: ['printer'],
        image: 'https://udger.com/pub/img/brand/kyocera_big.png',
        option: 'Kyocera'
    },
    {
        key: ['printer'],
        image: 'https://companiesmarketcap.com/img/company-logos/256/XRX.png',
        option: 'Xerox'
    },
    {
        key: ['smart watch'],
        image: 'https://cdn0.iconfinder.com/data/icons/logos-21/40/Samsung-128.png',
        option: 'Samsung Galaxy Watch'
    },
    {
        key: ['smart watch'],
        image: 'https://cdn3.iconfinder.com/data/icons/logos-brands-3/24/logo_brand_brands_logos_apple_ios-128.png',
        option: 'Apple Watch'
    },
    {
        key: ['smart watch'],
        image: 'https://cdn.iconscout.com/icon/free/png-256/fitbit-3628774-3030136.png',
        option: 'Fitbit'
    },
    {
        key: ['smart watch'],
        image: 'https://cdn.iconscout.com/icon/free/png-256/garmin-282119.png',
        option: 'Garmin'
    },
    {
        key: ['smart watch'],
        image: 'https://cdn.iconscout.com/icon/free/png-256/suunto-282825.png',
        option: 'Suunto'
    },
    {
        key: ['game system', 'game', 'gaming console', 'gaming', 'console', 'gaming system'],
        image: 'https://cdn.iconscout.com/icon/free/png-256/sega-2-282703.png',
        option: 'Sega'
    },
];
export const DEFAULT_SCHEDULE = {
    "Schedule": {
        "Monday": [
            {
                "Start_Time": "00:00:00.000Z",
                "End_Time": "23:59:00.000Z"
            }
        ],
        "Tuesday": [
            {
                "Start_Time": "00:00:00.000Z",
                "End_Time": "23:59:00.000Z"
            }
        ],
        "Wednesday": [
            {
                "Start_Time": "00:00:00.000Z",
                "End_Time": "23:59:00.000Z"
            }
        ],
        "Thursday": [
            {
                "Start_Time": "00:00:00.000Z",
                "End_Time": "23:59:00.000Z"
            }
        ],
        "Friday": [
            {
                "Start_Time": "00:00:00.000Z",
                "End_Time": "23:59:00.000Z"
            }
        ],
        "Saturday": [
            {
                "Start_Time": "00:00:00.000Z",
                "End_Time": "23:59:00.000Z"
            }
        ],
        "Sunday": [
            {
                "Start_Time": "00:00:00.000Z",
                "End_Time": "23:59:00.000Z"
            }
        ],

    },
    "Schedule_Amendments": []
};

const SECRET_KEY = 'cTAAAAAJcZVRqyHZKhI'

export function encryptString(string) {
    let ciphertext = CryptoJS.AES.encrypt(string, SECRET_KEY).toString();
    return ciphertext;
}

export function decryptString(ciphertext) {
    let bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText ? originalText : ciphertext;
}

export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export function ucwords(str, lower = false) {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    ;
}

export function toCustomStatus(status, bookingStatus) {
    let newStatus = status;
    if (status === 'Accepted')
        newStatus = 'Scheduled';
    else if (status === 'Confirmed')
        newStatus = 'Paid';
    else if (status === 'Completed' && bookingStatus === 'Confirmed')
        newStatus = 'Paid';

    return newStatus;

}

export function TouchPointLogout(id) {
    ApiBaseHelper.delete({
        url: `touchpoint/` + id
    })
    FirebaseHelper.logout()
}

export async function initiateWebConference({conversationId, caller, participant, redirect = true}) {
    await FirebaseHelper.set({
        path: `conferences/${conversationId}`,
        data: {
            "caller": caller,
            "participant": participant,
            "declined": false,
            "accepted": false,
            "connected": false,
        }
    });

    await FirebaseHelper.set({
        path: `users/${participant.id}`,
        data: conversationId
    });
    if (redirect)
        window.open(ROUTES.VIDEO_CONFERENCING.replace(':channel', conversationId), '_blank');
}

export async function getUserProfile(
    {
        orgId,
        token,
        tech,
        dispatch,
        redirectTarget = "_self",
        touchPoint,
        redirect = true,
        emailVerification = false,
        isSendVerificationEmail = false,
        role,
        organization,
        email,
        password,
        isAdmin = false,
        newSignup=false
    }
) {

    try {
        let getProfileResponse = await ApiBaseHelper.get({
            url: "user", headers: {
                Authorization: 'Bearer ' + token
            }
        });
        getProfileResponse = getProfileResponse.data.data;

        let validateTech = await ApiBaseHelper.get({
            url: `web/tech-account?email=${email}`,
        });

        let getEmailAndRoleStatus

        if(!isAdmin){
            getEmailAndRoleStatus = await ApiBaseHelper.post({
                url: "web/get-verification-and-role", payload: {
                    "email": email,
                    "password": password
                }
            });
            getEmailAndRoleStatus = getEmailAndRoleStatus.data.data;
        }

        const name = getProfileResponse.Full_Name.split(" ");
        if (tech) {
            let techProfile = {
                "firstname": name[0].charAt(0).toUpperCase() + name[0].slice(1),
                "lastname": name[1].charAt(0).toUpperCase() + name[1].slice(1),
                "phone": getProfileResponse.Personal_Phone,
                "imageUrl": getProfileResponse.imgUrl,
                "email": getProfileResponse.Email_Address,
                'token': token,
                'id': getProfileResponse.Id,
                'orgId': orgId,
                'techCode': getProfileResponse.Tech_Code ? getProfileResponse.Tech_Code : "null",
                'tech': isAdmin ? getProfileResponse?.Role?.Role === "tech" : getEmailAndRoleStatus?.Technician,
                'role': role,
                'organization':organization,
                'bio': getProfileResponse.Profile_Bio,
                'Provider_Service': 'Provider_Service' in getProfileResponse ? getProfileResponse.Provider_Service : [],
                'radius': getProfileResponse.Radius,
                'skillExp': getProfileResponse.Skills_Experience,
                'Stripe_Connect_Id': getProfileResponse.Stripe_Connect_Id,
                'Drive_URL': getProfileResponse.Drive_URL,
                'Referral_Link': getProfileResponse.Referral_Link,
                'Primary_Facility': getProfileResponse.Primary_Facility,
                'touch': touchPoint,
                'verified': isAdmin ? true : getEmailAndRoleStatus.Email_Verified,
                'email_verification_banner': isAdmin ? true : getEmailAndRoleStatus.Email_Verified,
                'profileUrl': getProfileResponse.Profile_URL

            };
            let currentUrl = window.location.href;
            if (currentUrl.includes("acceptance") == false) {
                if (techProfile['Stripe_Connect_Id'] == null || techProfile['Stripe_Connect_Id'] == "null") {
                    let stripeConnect = await ApiBaseHelper.post({
                        url: "stripe/connect-account",
                        payload: {
                            "id": techProfile['id'],
                            "email": techProfile['email'],
                            "first_name": techProfile['firstname'],
                            "last_name": techProfile['lastname'],
                            "phone_number": techProfile['phone'] ? techProfile['phone'] : '',
                            "address": techProfile['Primary_Facility'] ? techProfile['Primary_Facility']['Address'] : '',
                            "city": techProfile['Primary_Facility'] ? techProfile['Primary_Facility']['City'] : '',
                            "state": techProfile['Primary_Facility'] ? techProfile['Primary_Facility']['State'] : '',
                            "zip": techProfile['Primary_Facility'] ? techProfile['Primary_Facility']['zip code'] : '',
                            "token": token
                        },
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    });
                    techProfile['Stripe_Connect_URL'] = stripeConnect.data.data;
                } else {
                    let stripeConnect = await ApiBaseHelper.get({
                        url: "stripe/login-link/" + techProfile['Stripe_Connect_Id'],
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    });
                    techProfile['Stripe_Connect_URL'] = stripeConnect.data.data.url;
                }
            }

            dispatch(updateTech(techProfile))
            dispatch(updateUser(null));
            // reserve tech profile details in order to restore the details to original state during team switches
            dispatch(updateTechReserve(techProfile))
        } else {
            dispatch(updateUser({
                "firstname": name[0].charAt(0).toUpperCase() + name[0].slice(1),
                "lastname": name[1].charAt(0).toUpperCase() + name[1].slice(1),
                "phone": getProfileResponse.Personal_Phone,
                "imageUrl": getProfileResponse.imgUrl,
                "email": getProfileResponse.Email_Address,
                'Provider_Service': 'Provider_Service' in getProfileResponse ? getProfileResponse.Provider_Service : [],
                'token': token,
                'id': getProfileResponse.Id,
                'orgId': orgId,
                'tech': isAdmin ? getProfileResponse?.Role?.Role === "tech" : getEmailAndRoleStatus?.Technician,
                'touch': touchPoint,
                'Referral_Link': getProfileResponse.Referral_Link,
                'Primary_Facility': getProfileResponse.Primary_Facility,
                'verified': isAdmin ? true : getEmailAndRoleStatus.Email_Verified,
                'email_verification_banner': isAdmin ? true : getEmailAndRoleStatus.Email_Verified,
                'role': role,
                'organization': organization,
                'profileUrl': getProfileResponse.Profile_URL,
                'pendingTech': validateTech.data?.data[0]?.Status.toLowerCase() === 'pending review', // a user with a pending tech application
                'new_signup': newSignup
            }))
            dispatch(updateTech(null));
        }

        // await FirebaseHelper.login();

        if (isSendVerificationEmail) {
            await sendVerificationEmail({
                email: getProfileResponse.Email_Address,
                userId: getProfileResponse.Id,
                sendNotification: false,
                token
            })
        }

        if (emailVerification == false) {
            setTimeout(function () {
                if (redirect)
                    window.open(getHomePageURL(orgId), redirectTarget);
                else
                    window.location.href = window.location.href;
            }, 1000)
        }
    
    } catch (e) {
        console.log("error in fetching profile data")
        notification['error']({
            message: 'Error',
            description:
                'Having problem in fetching profile data, please try again later.',
        });
    }

}

export async function sendVerificationEmail({email, userId, token, sendNotification = true}) {
    let code = Math.floor(100000 + Math.random() * 900000);
    let encryptedCode = encryptString(JSON.stringify({
        code,
        token,
        userId,
        email,
        expires: moment().add(1, "days").utc(false)
    }))

    // console.log("encryptedCode", encode(encryptedCode, {mode: 'extensive'}))

    // save it in database
    // FirestoreHelper.saveEmailVerificationCode(
    //     {
    //         code,
    //         user_id: userId
    //     }
    // )

    // send email to user
    await ApiBaseHelper.post({
        url: `web/send-verify-email`,
        payload: {
            param: encryptedCode,
            email
        },
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });

    if (sendNotification) {
        Modal.info({
            title: "Verification link sent",
            content: <span>
                A link to verify your account has been sent to <Typography.Text strong>{email}</Typography.Text>. If you don't see the email in your main inbox, make sure to check your spam or junk folders.
            </span>,
        });
    }

}

export async function adminLogout({dispatch, notificationText = "You are logged out", history}) {
    notification.open({
        message: 'TecMe',
        description: notificationText
    });
    dispatch(updateTech(null));
    dispatch(updateUser(null));
    dispatch(updateAdmin(null));
    history.push(ROUTES.ADMIN_LOGIN);
}

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function convertDeliverables(data, filter) {
    let response = data.split('\n');
    if (isJson(data)) {
        response = JSON.parse(data);
        response = response[filter] != undefined ? response[filter] : []
    } else {
        if (filter == 'red_cross_mark')
            response = [];
    }
    return response
}

export function isDevEnv() {
    let url = window.location.href;
    return (url.includes("localhost")
        || url.includes("development")
        || url.includes("staging")
        || url.includes("acceptance"));

}

export function getHomePageURL(orgId) {
    return ROUTES.DASHBOARD.replace(":org_id", orgId);
}

export const normalFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
}

export const contactSupportModal = (role, cancel = false) => {
    let content = "";
    if (cancel) {
        content = role == 'tech' ? "To cancel this service please call the TecMe support line and explain the situation to a support specialist. This is so we can maintain the highest customer satisfaction possible through the service cancellation"
            : "Please call the TecMe support line to report your technician. We will also ensure no fees are charged for the cancellation of this booking. Phone number: (888) 678-3263";
    } else
        content = <div>
            To contact support representative:
            <ul>
                <li>Email — support@tecme.io</li>
                <li>Call: (888) 678-3263</li>
                <li>Submit a ticket from the website</li>
            </ul>
        </div>
    return Modal.info({
        title: "Contact Support",
        content,
        centered: true,
    });
}

export function restrictUserForCreatingBookingModal({user_id, email, role = "customer", token}) {
    Modal.info({
        title: "Your account is not verified.",
        content:
            role == 'customer' ?
                'Please verify your email address first to create booking. Check your inbox and click on verify link.' :
                'Please verify your email address first to accept service request. Check your inbox and click on verify link.'
    });

    sendVerificationEmail({
        email,
        userId: user_id,
        sendNotification: false,
        token
    });
}

export const convertCentsIntoDollarsString = (cents) => (cents / 100).toFixed(0)

export const compressHipaaImages = ({url, height = 100, width = 100}) => {

    if (!url.includes('hipaa'))
        return url;
    let bucket = 'hipaadev';
    if (BASE_URL === STAGING_SERVER)
        bucket = 'hipaastaging';
    else if (BASE_URL === ACCEPTANCE_SERVER || BASE_URL === PROD_SERVER)
        bucket = 'hipaadoc';

    let jsonParams = {
        bucket,
        key: `uploaded${url.split('uploaded')[1]}`,
        edits: {
            resize: {
                height,
                width,
                fit: "cover"
            }
        }
    };

    return `https://dn44b65i5fg54.cloudfront.net/${btoa(JSON.stringify(jsonParams))}`
}

export const getZipCodeFromGeocodeResponse = (response) => {
    if (!response.results?.length) return;
    let zipCodeIndex = 0;
    const addressComponentIndex = response.results.findIndex((result, i) => {
        return result.address_components.find((address_component, index) => {
                const check = address_component.types?.includes("postal_code")
                if (check) zipCodeIndex = index
                return check
            }
        )
    });

    return addressComponentIndex > -1 ? response.results[addressComponentIndex].address_components[zipCodeIndex].long_name : "";
};

export const capitalizeFirstLetter = string => !string ? "" : string?.replace(/\s+/g, ' ').charAt(0)?.toUpperCase() + string?.replace(/\s+/g, ' ')?.slice(1)

export const getSpecificYears = (length) => {
    const year = new Date().getFullYear();

    return Array.from({length}, (_, i) => year - length + i + 1).sort((a,b) => a > b ? -1 : 0);
}


export const sortDates = (array) => !array.length ? ([]) : array.sort((a,b) => (moment(a.Start_Date || a["Start Date"]).isSameOrAfter(b.Start_Date || b["Start Date"]) ? -1 : 0))

export const capitalizeFirstWordOfEveryLetter = string => !string ? "" : string?.replace(/\s+/g, ' ')?.split(" ").map((name) => `${name[0]?.toUpperCase()}${name?.slice(1)}`)?.join(" ")


// For Small Data Set
// export const mapCityStateWithCount = async (activeUser) => {
//     const result = []
//     for (let i = 0; i < STATES.length; i++) {
//         const state = STATES[i];
//         const citiesList = citiesStatesWithLngLatMin.filter((item) => state.State.toLowerCase() === item.state.toLowerCase());

//         if(citiesList?.length) {
//             for (let index = 0; index < citiesList.length; index++) {
//                 const city = citiesList[index];
//                 const res = await ApiBaseHelper.get({
//                     url: `technician?latitude=${city.latitude}&longitude=${city.longitude}&bookingType=home`,
//                     // url: `web/technicians?latitude=${city.latitude}&longitude=${city.longitude}&bookingType=home`,
//                     headers: {
//                         'Authorization': `Bearer ${activeUser.token}`
//                     }
//                 })
                
//                 if(res.data?.data?.length && res.data.data.filter(({ data }) => data.imgUrl && data.imgUrl !== 'TO BE IMPLEMENTED IN MIDDLEWARE').length >= MINIMUM_NUMBER_OF_TECHS) {
//                     result.push({
//                         stateCode: state.Code,
//                         state: state.State,
//                         city: city.city,
//                         longitude: city.longitude,
//                         latitude: city.latitude,
//                         count: res.data.data.length
//                     })        
//                 }
//             }
//         }
//     }

//     console.log({ result })
// }

// For Large Data Set
// export const mapCityStateWithCount = async (activeUser) => {
//     const result = []
//     for (let i = 0; i < STATES.length; i++) {
//         const state = STATES[i];
//         const citiesList = citiesStatesWithLngLat.filter(({ STATE_CODE }) => state.Code === STATE_CODE);
//         if(citiesList?.length) {
//             for (let index = 0; index < citiesList.length; index++) {
//                 const city = citiesList[index];
//                 const res = await ApiBaseHelper.get({
//                     url: `technician?latitude=${city.LATITUDE}&longitude=${city.LONGITUDE}&bookingType=home`,
//                     // url: `web/technicians?latitude=${city.LATITUDE}&longitude=${city.LONGITUDE}&bookingType=home`,
//                     headers: {
//                         'Authorization': `Bearer ${activeUser.token}`
//                     }
//                 })
                
//                 if(res.data?.data?.length && res.data.data.filter(({ data }) => data.imgUrl && data.imgUrl !== 'TO BE IMPLEMENTED IN MIDDLEWARE').length >= MINIMUM_NUMBER_OF_TECHS) {
//                     result.push({
//                         stateCode: state.Code,
//                         state: state.State,
//                         city: city.CITY,
//                         longitude: city.LONGITUDE,
//                         latitude: city.LATITUDE,
//                         count: res.data.data.length
//                     })        
//                 }
//             }
//         }
//     }

//     console.log({ result })
// }

export const getStatesWhereTechExists = () => {
    const filteredStates = []
    MATCHED_CITIES_STATES.forEach(item => {
        if(!filteredStates.length) filteredStates.push(item)
        if(filteredStates.every(s => s.stateCode !== item.stateCode)) filteredStates.push(item)
    })

    return filteredStates
}

export const getCitiesWhereTechExists = (state) => !state ? [] : MATCHED_CITIES_STATES.filter(item => item.state.toLowerCase() === state.toLowerCase())

export const getSelectedCityWhereTechExists = (state, city) => (!state || !city) ? ({}) : MATCHED_CITIES_STATES.find(item => item.state.toLowerCase() === state.toLowerCase() && item.city.toLowerCase() === city.toLowerCase())

export const convertToLocalTime = (timeStamp) => {
    return moment(moment.utc(timeStamp).toDate()).local()
}

export const getLocalTimeFormat = (timeStamp) => {
    const localTimestamp = moment(moment.utc(timeStamp).toDate()).local()
    return localTimestamp.format("MMM Do YYYY") + ' | ' + localTimestamp.format("ddd") + ' | ' + localTimestamp.format("h:mm a")
}

export const getTableTimeFormat = (timeStamp) => {
    const localTimestamp = moment(moment.utc(timeStamp).toDate()).local()
    return localTimestamp.format("MMM Do YYYY") + ', ' + localTimestamp.format("h:mm a")
}

export const getDottedTimeFormat = (timeStamp) => {
    const localTimestamp = moment(moment.utc(timeStamp).toDate()).local()
    return localTimestamp.format("DD") + ' ' + localTimestamp.format("MMMM") + ', ' + localTimestamp.format("YYYY")
}

export const getFromNowDateFormat = (timeStamp) => {
    const localTimestamp = moment(moment.utc(timeStamp).toDate()).local()
    return localTimestamp.fromNow()
}

export const truncateText = (text, maxNum) => {
    return text?.length <  maxNum ? text : `${text.slice(0,  maxNum)}...`
}

export const callPostApiWithErrorHandling = async ({ url, payload }) => {
    try {
        return await ApiBaseHelper.post({ url, payload })
    } catch (error) {
        notification.error({
            message: 'TecMe',
            description: error?.response?.data?.message || 'Something went wrong, please try again later.'
        });
    }
}

export const filterWeeklyTimeSheet = (timeSheetData = []) => {
    const newFilteredTimeSheetData = timeSheetData.filter(data => {
        const currentDate = moment();
        const clockInDate = moment(data.Clock_In_Date_Time);
        const clockOutDate = moment(data.Clock_Out_Date_Time);

        const currentWeek = currentDate.week();
        const clockInWeek = clockInDate.week();
        const clockOutWeek = clockOutDate.week();

        if (currentWeek === clockInWeek && currentWeek === clockOutWeek) {
            return true
        }

        return false
    })

    return newFilteredTimeSheetData
}
export const getMarketingWebsiteURL = () => {
    let URL = 'https://tecme.io';
    // if (BASE_URL === DEV_SERVER)
    //     URL = 'https://website.tecme.io';
    // if (BASE_URL === STAGING_SERVER)
    //     URL = 'https://website.tecme.io';
    // else if (BASE_URL === ACCEPTANCE_SERVER || BASE_URL === PROD_SERVER)
    //     URL = 'https://tecme.io';
    return URL;
}

export const getSpecializationExample = (specialization) => {
    let example = [];
    switch (specialization) {
        case 'business software':
            example = ["Accounting software", "Customer Relationship Management (CRM) software", "Enterprise Resource Planning (ERP) software", "Human Resources Management (HRM) software", "Project Management software", "Business Intelligence (BI) software", "Sales Force Automation (SFA) software", "Marketing Automation software", "Inventory Management software", "Point of Sale (POS) software"]
            ;
            break;
        case 'enterprise resource planning (erp)':
            example = ["SAP", "Oracle ERP Cloud", "Microsoft Dynamics 365", "NetSuite", "Epicor ERP", "Infor ERP", "Acumatica Cloud ERP", "Sage Intacct", "Syspro ERP", "IFS Applications"]
            ;
            break;
        case 'customer relationship management (crm)':
            example = ["Salesforce", "HubSpot CRM", "Microsoft Dynamics 365", "Zoho CRM",    "SugarCRM", "Pipedrive", "Freshworks CRM", "Insightly", "Agile CRM", "Copper"]
            ;
            break;
        case 'telecommunications':
            example = ["Mobile network optimization", "IP network design and optimization",    "Network function virtualization (NFV)", "Software-defined networking (SDN)", "Fiber optic network design and implementation", "Wireless network design and optimization",    "Telecom expense management (TEM)", "Unified communications and collaboration (UC&C)",    "Network security and risk management", "IoT and M2M solutions"]
            ;
            break;
        case 'it management':
            example = ['Microsoft System Center', 'SolarWinds', 'ManageEngine', 'BMC Remedy', 'Ivanti'];
            break;
        case 'cybersecurity':
            example = ["Network security", "Endpoint security", "Cloud security", "Data loss prevention (DLP)", "Identity and access management (IAM)", "Application security",    "Penetration testing and vulnerability assessment", "Security information and event management (SIEM)", "Security assessment and compliance", "Cybersecurity risk management"]
            ;
            break;
        case 'windows operating systems':
            example = ["Windows Server Administration", "Windows Networking", "Windows Active Directory", "Windows Security", "Windows PowerShell Scripting", "Windows Deployment", "Windows Performance Tuning", "Windows Troubleshooting", "Windows Virtualization", "Windows Remote Desktop Services (RDS)"]
            ;
            break;
        case 'virtual machines':
            example = ["VMware vSphere", "Microsoft Hyper-V", "Citrix Hypervisor (formerly XenServer)", "Oracle VM VirtualBox", "Red Hat Virtualization", "Proxmox Virtual Environment", "KVM (Kernel-based Virtual Machine)", "Xen Project", "Docker", "Vagrant"]
            ;
            break;
        case 'operating systems and virtualization':
            example = ["Windows Server Administration", "Linux Server Administration", "VMware vSphere", "Microsoft Hyper-V", "Citrix Hypervisor (formerly XenServer)", "Oracle VM VirtualBox", "Red Hat Virtualization", "Proxmox Virtual Environment", "KVM (Kernel-based Virtual Machine)", "Xen Project"]
            ;
            break;
        case 'computer hardware':
            example = ["PC assembly and repair", "Hardware troubleshooting and maintenance", "Printers and peripherals installation and configuration", "Network hardware installation and configuration", "Server hardware installation and configuration", "Storage hardware installation and configuration", "Embedded systems design and development", "Microcontroller programming and development", "ASIC design and development", "FPGA design and development"]
            ;
            break;
        case 'wireless networking':
            example = ["Wireless network design and optimization", "Wi-Fi network installation and configuration", "Wireless security", "Mobile device management (MDM)", "Mobile application management (MAM)", "Location-based services", "Wireless site surveys", "Wireless troubleshooting", "Mobile data analytics", "Bluetooth and NFC technologies"]
            ;
            break;
        case 'hardware and networking':
            example = ["PC assembly and repair", "Hardware troubleshooting and maintenance", "Printers and peripherals installation and configuration", "Network hardware installation and configuration", "Wireless network design and optimization", "Server hardware installation and configuration", "Storage hardware installation and configuration", "Network security and risk management", "Unified communications and collaboration (UC&C)", "Virtualization and cloud computing"]
            ;
            break;
        case 'technical troubleshooting':
            example = ["Hardware troubleshooting", "Software troubleshooting", "Network troubleshooting", "Operating system troubleshooting", "Application troubleshooting", "Mobile device troubleshooting", "Printer troubleshooting", "Server troubleshooting", "Cloud service troubleshooting", "Security troubleshooting"]
            ;
            break;
        case 'on-site it support':
            example = ["Hardware installation and configuration", "Software installation and configuration", "Network installation and configuration", "Printer and peripheral installation and configuration", "Mobile device setup and configuration", "Remote access and VPN setup", "User training and education", "Hardware and software maintenance", "Data backup and recovery", "Security and antivirus setup"]
            ;
            break;
        case 'remote it support':
            example = ["Help desk support", "Technical support", "Software troubleshooting", "Remote desktop support", "Email and messaging support", "Mobile device support", "Cloud service support", "Security and antivirus support", "Data backup and recovery support", "Remote access and VPN support"]
            ;
            break;
        case 'data management and storage':
            example = ["Database administration", "Data analysis and reporting", "Data modeling and architecture", "Big data management and analysis", "Data warehousing", "Cloud storage and management", "Backup and disaster recovery", "Data security and privacy", "Data governance and compliance", "Data integration and migration"]
            ;
            break;
        case 'data warehousing':
            example = ["Data modeling and architecture", "ETL (extract, transform, load) design and implementation", "Data quality and cleansing", "Metadata management", "Data governance and compliance", "Business intelligence and analytics", "Data warehouse optimization and performance tuning", "Master data management", "Data migration and integration", "Data warehouse automation"]
            ;
            break;
        case 'data archiving':
            example = ["Data classification and retention policies", "Data backup and recovery", "Data compression and deduplication", "Data encryption and security", "Long-term data storage planning and management", "Data migration and consolidation", "Data retrieval and access management", "Data disposal and destruction", "Legal and regulatory compliance", "Cloud-based archiving solutions"]
            ;
            break;
        default:
            example = [];
            break;
    }
    return example;
}

// Check if user can read page only according to their roles
export const canReadPageOnly = (role) => {
    const pathname = window.location.pathname;
    if(role?.toLowerCase() === "recruiter" && RecruiterRouteBlacklist.some((route) => pathname.includes(route))){
        return true
    } else if(role?.toLowerCase() === "manager" && ManagerRouteBlacklist.some((route) => pathname.includes(route))){
        return true
    }else{
        return false
    }
}

export const isAdministrator = (role) => {   
    if(role?.toLowerCase() === "administrator"){
        return true
    }else{
        return false
    }
}


export function getCreditCardInfo(cardNumber) {
    // Remove all non-digit characters from the card number
    const cleanedCardNumber = cardNumber.replace(/\D/g, '');

    // Get the first 6 digits of the card number
    const firstSixDigits = cleanedCardNumber.slice(0, 6);
  
    // Define regular expressions to match different credit card types
    const cardRegexes = [
      { regex: /^4[0-9]{5}/, name: 'Visa', logo: VisaSvg },
      { regex: /^5[1-5][0-9]{4}/, name: 'Mastercard', logo: MasterCardSvg },
      { regex: /^3[47][0-9]{4}/, name: 'American Express', logo: AmericanExpressSvg  },
      { regex: /^(?:2131|1800|35\d{2})\d{3}/, name: 'JCB', logo: JcbSvg },
      { regex: /^6(?:011|5[0-9]{2})[0-9]{3}/, name: 'Discover', logo: DiscoverSvg },
      { regex: /^3(?:0[0-5]|[68][0-9])[0-9]{3}/, name: 'Diners Club', logo: DinersClubSvg }
    ];
  
    // Find the matching card type
    const matchedCard = cardRegexes.find(card => card.regex.test(firstSixDigits));
  
    // Return the card name or 'Unknown' if no match was found
    return matchedCard ? matchedCard : null;
  }

// Add a subdomain to the current URL

export function addSubdomainToBaseUrl(subdomainToAdd) {
    const url = new URL(window.location.href);
    // Extract the full domain (including subdomains if any)
    let fullDomain = url.hostname;
    
    // Check if the domain already has a subdomain
    const parts = fullDomain.split('.');
    
    if (parts.length > 2 && parts[0] !== 'app') {
        fullDomain = subdomainToAdd + '.' + fullDomain;
      } else if(parts.length > 2 && parts[0] === 'app'){
          fullDomain = fullDomain.replace("app", subdomainToAdd)
      } else {
        // For second-level domains (e.g., example.io), just add the subdomain before the domain
        parts.splice(0, 0, subdomainToAdd);
        fullDomain = parts.join('.');
      }
  
    // Construct the new URL with the updated domain
    const newUrl = `${url.protocol}//${fullDomain}`;
  
    // Return the modified URL without any path, query, or fragment
    return newUrl;
}

export function removeSubdomainFromUrl(subdomainToRemove, originalSubdomain = 'app') {
    const url = new URL(window.location.href);
    let parts = url.hostname.split('.');
    
    // If the first part of the hostname matches the subdomain to remove, remove it
    if (parts[0] === subdomainToRemove) {
        parts.shift(); // Remove the first part
        if(parts.length == 2){
            parts.unshift(originalSubdomain)
        }
    }
    
    const newHostname = parts.join('.');
    const newUrl = `${url.protocol}//${newHostname}${url.port ? ':' + url.port : ''}`;
  
    return newUrl;
}

  // check for light color and convert to darker shade
function hexToRGB(hex) {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    return [r, g, b];
}

function luminance(r, g, b) {
    const a = [r, g, b].map(function(v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function darkenHex(hex, amount=0.7) {
    const [r, g, b] = hexToRGB(hex);
    return "#" + [r, g, b].map(x => {
        const darker = Math.floor(x * amount).toString(16);
        return darker.length === 1 ? "0" + darker : darker;
    }).join("");
}

export const convertIfLight = (hex) => {
    const [r, g, b] = hexToRGB(hex);
    if (luminance(r, g, b) > 0.5) { // Assuming luminance threshold of 0.5 to consider it light
        return darkenHex(hex); // Darken if light
    }
    return hex; // Return original if not light
}

// check if string is a valid url
export const isValidUrl = (string) => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$','i' // fragment locator
    );
    return !!pattern.test(string);
}

// check if iframe is valid
export function isValidIframe(iframeString) {
    const iframeRegex = /<iframe\s+[^>]*src=["']([^"']+)["'][^>]*><\/iframe>/i;
    return iframeRegex.test(iframeString);
}

// remove html tags from string
export function stripHtml(htmlString) {
    // Use a regular expression to remove HTML tags
    const cleanText = htmlString.replace(/<\/?[^>]+(>|$)/g, "");
    return cleanText;
  }

// Firebase conversations helper

// Function to get and set group conversations.
export const getAndSetGroupCoversation = async (groupName) => {
    await FirebaseHelper.login();
    const participantsResponse = await FirestoreHelper.getAllGroupConversations();
    const groupConversations = [];
    participantsResponse.forEach((doc) => {
      let item = doc.data();
      item.id = doc.id;
      if (item.group && item.participants?.length) {
        groupConversations.push({ ...item});
      }
    });
    console.log(groupConversations.find((c) => c.group.toLowerCase() === (groupName?.toLowerCase())))
    return (groupConversations.find((c) => c.group.toLowerCase() === (groupName?.toLowerCase())))
}

// Function to create group chat
export const createGroupChat = async (groupName, tech) => {  
    const rtmRepository = new RtmRepository(tech?.token);
    const techName = `${tech?.firstname} ${tech?.lastname}`;
    let group = ucwords(groupName);
    
    await FirebaseHelper.login();
    
    return rtmRepository.createGroupConversation([tech?.id])
        .then(async (response) => {
            let conversationId = response.data.data.id;
            
            await FirestoreHelper.createUser({
                hipaa_id: tech?.id,
                display_name: techName,
                profile_picture: tech?.imageUrl,
                role: "Technician",
            });
            
            let message = `${group} group chat created by ${techName} to manage company conversations`;

            await FirestoreHelper.createConversation({
                conversation_id: conversationId,
                message: encryptString(message),
                participants: [tech?.id],
                user_id: tech?.id,
                group,
            });

            return FirestoreHelper.sendMessage({
                conversation_id: conversationId,
                message: encryptString(message),
                user_id: tech?.id,
            });
        });
};

// Function to check project link and return name and logo
export function addProjectManagementLinkLogo(projectLink, returnType = "both") {
    if (typeof projectLink !== "string") {
        console.log(typeof projectLink)
        console.error("Invalid project link:", projectLink);
        return returnType === "logo" ? null : { name: "Unknown", logo: null };
    }
    const platforms = [
        {
            name: "Jira",
            regex: /^(https?:\/\/)?(www\.)?(jira\.com|[a-zA-Z0-9-]+\.atlassian\.net)(?:\/(?:jira\/software\/projects\/|browse\/|projects\/|board\/|issue\/|jira\/projects\/)?([A-Z]+-\d*|[a-zA-Z0-9-]+))?(?:\/boards\/\d+)?(?:\?.*)?$/,
            logo: JiraIcon,
        },
        {
            name: "Asana",
            regex: /https?:\/\/(?:app\.)?asana\.com\/[^\s]+/g,
            logo: AsanaIcon,
        },
        {
            name: "Trello",
            regex: /https?:\/\/(?:www\.)?trello\.com\/[^\s]+/g,
            logo: TrelloIcon,
        },
        {
            name: "ClickUp",
            regex: /https?:\/\/(?:www\.)?clickup\.com\/[^\s]+/g,
            logo: ClickupIcon,
        },
        {
            name: "Notion",
            regex: /https?:\/\/(?:www\.)?notion\.so\/[^\s]+/g,
            logo: NotionIcon,
        },
    ];

    for (const platform of platforms) {
        if (platform.regex.test(projectLink)) {
            if (returnType === "name") return platform.name;
            if (returnType === "logo") return platform.logo;
            return { name: platform.name, logo: platform.logo };
        }
    }

    const subdomainMatch = projectLink && projectLink.match(/https?:\/\/(?:www\.)?([^\.\/]+)\./);;
    const subdomain = subdomainMatch ? subdomainMatch[1] : "Unknown";
    const genericLogo = null;

    if (returnType === "name") return subdomain;
    if (returnType === "logo") return genericLogo;

    return { name: subdomain, logo: genericLogo };
}

// get src from iframe
export function getIframeSrc(iframe) {
    const src = iframe?.match(/src=["']([^"']*)["']/);
    return src ? src[1] : "";
}

// clean iframe content
export function cleanIframeContent(html) {
    return html.replace(/(<iframe\b[^>]*>)(.*?)(<\/iframe>)/gis, "$1$3");
}

  export const DEVICE_BRANDS = [
    "Apple",
    "Samsung",
    "HP",
    "Dell",
    "Lenovo",
    "Asus",
    "Microsoft",
    "Sony",
    "LG",
    "Xiaomi",
    "Huawei",
    "Google",
    "Motorola",
    "Nokia",
    "OnePlus",
    "Oppo",
    "Vivo",
    "Acer",
    "Toshiba",
    "Panasonic",
    "GoPro",
    "DJI",
    "Fitbit",
    "Garmin",
    "Bose",
    "JBL",
    "Sonos",
    "Amazon (for Amazon Echo devices)",
    "Ring",
    "Nest (for Nest smart home devices)",
    "Philips Hue (for smart lighting)",
    "Ecobee",
    "August (for smart locks)",
    "Arlo (for smart security cameras)",
    "iRobot (for robot vacuum cleaners)",
    "Anker",
    "Belkin",
    "TP-Link",
    "Logitech",
    "Razer",
    "Canon",
    "Nikon",
    "Fujifilm",
    "GoPro",
    "HTC",
    "Blackberry",
    "Microsoft Surface",
    "Wacom",
    "Adobe",
    "Western Digital",
  ]
  