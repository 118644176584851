import React from 'react'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'
import { Button } from 'antd'

const HiddenBanner = ({text, loading, hidden, handleClick}) => {
    return (
        <div className='itsc-hidden-banner'>
            <p className='m-0'>{text ? text : "This section will not be displayed on your webpage because you've hidden it."}</p> 
            <Button  style={{height:30, fontSize:14, color:SECONDARY_COLOR }} loading={loading} onClick={handleClick}>
               { hidden ? 'Display' : 'Hide' }
            </Button>
        </div>
    )
}

export default HiddenBanner