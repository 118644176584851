import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION } from '../../../../../utils/constants'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import ROUTES from '../../../../../utils/routes'
import { Avatar, Button, Form, Input, Layout, List, message, Modal, notification, Space, Switch, Tabs, Tooltip, Typography, Upload } from 'antd'
import './assets/css/styles.scss'
import { useLocation } from 'react-router-dom'
import { capitalizeFirstWordOfEveryLetter, cleanIframeContent, getIframeSrc, isValidIframe, isValidUrl, nameFormat } from '../../../../../utils/helper'
import { InfoCircleOutlined, SecurityScanOutlined, UploadOutlined } from '@ant-design/icons'
import ActionModal from '../../../tech/itc/create-itc/components/delete-modal'
import { useSelector } from 'react-redux'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import PageFallback from '../../../../components/page-fallback'
import CustomRenderEmpty from '../../../../components/custom-render-empty'

const { Content } = Layout
const {Title, Text, Paragraph} = Typography

const CybersecurityDetails = () => {
    const tech = useSelector(state => state.tech)
    const client = useSelector(state => state.user)
    const { state } = useLocation()
    const [openSetUpModal, setOpenSetUpModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [submittingCybersecurity, setSubmittingCybersecurity] = useState(false)
    const [deletingCybersecurity, setDeletingCybersecurity] = useState(false)
    const [editCybersecurityDetails, setEditCybersecurityDetails] = useState(null)
    const [loadingCybersecurity, setLoadingCybersecurity] = useState(false)
    const [cybersecurityList, setCybersecurityList] = useState([])
    const [deleteId, setDeleteId] = useState(null)
    const [form] = Form.useForm()
    const [file, setFile] = useState(null)

    const authUser = tech || client

    const props = {
        accept: 'image/*',
        multiple: false,
        showUploadList: true,
        beforeUpload: (file) => {
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                message.error('Image must smaller than 5MB!');
            } else {
                // getBase64(file, imageUrl =>{
                //     setLogoUrl(imageUrl)
                // });
                setFile(file)
            }
            return false;
        },
        onDrop(e) {
           setFile(e.dataTransfer.files[0]);
        },
        onRemove: (file) => {
            setFile(null);
            return true;
        }
    };

    const fetchCybersecurity = () => {
        setLoadingCybersecurity(true)
        ApiBaseHelper.get({
            url: `cybersecurity/all?orgId=${state.id}`, 
            headers: {
                Authorization: 'Bearer ' + authUser?.token
            }
        }).then((res) => {
            setCybersecurityList(res?.data?.data)
            setLoadingCybersecurity(false)
        }).catch((error) => {
            console.error(error)
            setLoadingCybersecurity(false)
        })       
    }

    const handleCybersecuritySubmit = (values) => {
        setSubmittingCybersecurity(true)
        if(editCybersecurityDetails){
            let payload = {
                Label: values.label,
                iFrame: cleanIframeContent(values.iframe),
                Private: values.private ? "1" : "0"
            }
            ApiBaseHelper.put({
                url: `cybersecurity/${editCybersecurityDetails.id}`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload: !file ?  {
                    ...payload,
                    Icon: null 
                } : payload
                
            }).then( async (res) => {
                if(res){
                    if(file && isValidUrl(file) === false){
                        handleCybersecurityFileSubmit(editCybersecurityDetails.id)
                        return
                    }
                }
                setSubmittingCybersecurity(false)
                notification.success({
                    message: 'TecMe',
                    description: 'Cybersecurity dashboard details updated successfully'
                })
                setEditCybersecurityDetails(null)
                fetchCybersecurity()
                setOpenSetUpModal(false)
                form.resetFields()
            }).catch((err) => {
                setSubmittingCybersecurity(false)
                notification.error({
                    message: 'TecMe',
                    description: 'An error occurred while updating cybersecurity dashboard details'
                })
                console.log(err)
            })
        }else{
            ApiBaseHelper.post({
                url: `cybersecurity`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload:  {
                    Label: values.label,
                    iFrame: cleanIframeContent(values.iframe),
                    Private: values.private ? "1" : "0",
                    Tech_ID: tech.orgId,
                    Client_Org: state.id,
                    Created_By: `${tech.firstname} ${tech.lastname}`,
                    Contact_Id: tech.id,
                    Profile_Img: tech.imageUrl
                }
                
            }).then( async (res) => {
                if(res){
                    if(file){
                        handleCybersecurityFileSubmit(res.data.data.id)
                        return
                    }
                }
                setSubmittingCybersecurity(false)
                notification.success({
                    message: 'TecMe',
                    description: 'Cybersecurity dashboard added successfully'
                })
                fetchCybersecurity()
                setOpenSetUpModal(false)
                form.resetFields()
            }).catch((err) => {
                setSubmittingCybersecurity(false)
                notification.error({
                    message: 'TecMe',
                    description: 'An error occurred while adding cybersecurity dashboard'
                })
                console.log(err)
            })
        }
    }

    const handleCybersecurityFileSubmit = (id) => {
        const payload = new FormData();
        payload.append('file', file)
        ApiBaseHelper.post({
            url: `cybersecurity/image/${id}`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: payload
        }).then(async (res) => {
            notification.success({
                message: 'TecMe',
                description: 'Cybersecurity dashboard added successfully'
            })
            setSubmittingCybersecurity(false)
            fetchCybersecurity()
            setOpenSetUpModal(false)
            form.resetFields()
        }).catch((err) => {
            setSubmittingCybersecurity(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while adding cybersecurity dashboard'
            })
            console.log(err)
        })
    }

    const deleteCybersecurity = () => {
        setDeletingCybersecurity(true)
        ApiBaseHelper.delete({
            url: `cybersecurity/${deleteId}`,
            headers: {
                Authorization: `Bearer ${tech.token}`
            },
        }).then((res) => {
            if (res.data) {
                notification['success']({
                    message: 'TecMe',
                    description: "Cybersecurity dashboard deleted successfully",
                });
                fetchCybersecurity()
                setDeleteModal(false)
                setDeletingCybersecurity(false)
            }
        }).catch((error) => {
            setDeletingCybersecurity(false)
            notification['error']({
                message: 'TecMe',
                description: "An error Occurred",
            });
        })
    }

    useEffect(() => {
        fetchCybersecurity()
    }, [])

    useEffect(() => {
        if(editCybersecurityDetails){
            form.setFieldsValue({
                label: editCybersecurityDetails.Label,
                iframe: editCybersecurityDetails?.iFrame,
                private: editCybersecurityDetails?.Private === "1" ? true : false
            })
            setFile(editCybersecurityDetails?.Icon)
        }
    }, [editCybersecurityDetails])

    if(loadingCybersecurity){
        return <PageFallback />
    }


    return (
        <>
            <MetaTags>
                <title>Cybersecurity Details - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.DASHBOARD,  
                        breadcrumbName: 'Dashboard',
                    },
                    {
                        breadcrumbName: 'Cybersecurity',
                    },
                ]}
            />
            <Content className="main">
                <div className='page'>
                    <div className="auth-container">
                        <div className="auth-page-ctn">
                            <div className='cyber-heading'>
                                <div>
                                <Title className='mb-1 main-heading'>
                                    Cybersecurity
                                </Title>
                                <Space>
                                    <Paragraph className='mb-0 cybersecurity-subtitle'>
                                        {
                                            tech ?
                                            'You can set up as many cybersecurity dashboards as possible for your clients via embeddable iframes' :
                                            'This page consists of all cybersecurity dashboards created and managed by your technicians'
                                        }
                                    </Paragraph>
                                   {
                                        tech &&
                                        <Button
                                            onClick={() => setOpenSetUpModal(true)}
                                        >
                                            Set Up
                                        </Button>
                                    }
                                </Space>
                                </div>
                                <Space>
                                    <Avatar shape='circle' size={45} src={state.Logo} alt={state.Name}>
                                        {state.Name?.[0]?.toUpperCase()}
                                    </Avatar>
                                    <Text className='font-weight-500'>
                                        {capitalizeFirstWordOfEveryLetter(state.Name)}
                                    </Text>
                                </Space>
                            </div>
                            {
                                cybersecurityList.length === 0 ? 
                                <CustomRenderEmpty
                                    description={ tech ? "You are yet to set up any cybersecurity dashboard for this client" : "No dashboard has been set up by your technicians yet"}>
                                    <List
                                        dataSource={[]} 
                                    />
                                </CustomRenderEmpty> :
                                <Tabs defaultActiveKey="1">
                                    {
                                        cybersecurityList.filter((item) =>{
                                            if(tech && item.Private === "1"){
                                                return (item.Tech_ID === tech?.orgId)
                                            }else{
                                                return item
                                            }
                                        }).map((item) => (
                                            <Tabs.TabPane 
                                                tab={
                                                    <div className='d-flex align-content-center gap-2'>
                                                        {
                                                            item.Icon && <img className='mt-1' src={item.Icon} alt={item.Label} style={{width:14, height:14}} /> 
                                                        }
                                                        {item.Label}
                                                        {
                                                            item.Private === "1" && 
                                                            <Tooltip
                                                                title={ tech ? `This is a private dashboard between you and ${state.Name}. It won't be accessible by other technicians` : `This is a private dashboard between you and ${nameFormat(item?.custom_data?.Created_By)}. It won't be accessible by other technicians`}
                                                            >
                                                                <InfoCircleOutlined className='cyber-info-icon' style={{fontWeight: "700"}} />
                                                            </Tooltip>  
                                                        }
                                                    </div>
                                                } 
                                                key={item.id}
                                            >
                                                <div className="dashboard-section-iframe">
                                                    <iframe src={isValidIframe(item.iFrame) ? getIframeSrc(item.iFrame) : item.iFrame} style={{ width: "100%", minHeight: "90vh"}}>loading...</iframe>
                                                </div>
                                                <div className='d-flex justify-content-between my-4 flex-wrap gap-3'>
                                                    <Space align='center' wrap>
                                                        <Paragraph className='m-0'>Dashboard created and managed by </Paragraph>
                                                        <Space>
                                                            <Avatar shape='circle' size={25} src={item?.custom_data?.Profile_Img} alt={item?.custom_data?.Created_By}>
                                                                {item?.custom_data?.Created_By?.[0]?.toUpperCase()}
                                                            </Avatar>
                                                            <Text style={{color:"#000"}} strong>{nameFormat(item?.custom_data?.Created_By)}</Text>
                                                        </Space>
                                                    </Space>
                                                    {
                                                        item.Tech_ID === authUser.orgId && 
                                                        <Space >
                                                            <Button
                                                                onClick={() =>{ 
                                                                    setOpenSetUpModal(true)
                                                                    setEditCybersecurityDetails(item)
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>
                                                            <Button 
                                                                type='primary'
                                                                onClick={() => {
                                                                    setDeleteModal(true)
                                                                    setDeleteId(item.id)
                                                                }}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </Space>
                                                    }
                                                </div>
                                            </Tabs.TabPane>
                                        ))
                                    }
                                </Tabs>
                            }
                        </div>
                    </div>
                </div>
            </Content>
            <Modal 
                open={openSetUpModal} 
                onOk={() => setOpenSetUpModal(false)} 
                onCancel={() => setOpenSetUpModal(false)}
                footer={null}
            >
                <Form
                    onFinish={handleCybersecuritySubmit}
                    form={form}
                    layout='vertical'
                    className="cybersecurity-form"
                >
                    <Title level={4} className="itc-form-title">Setup Cybersecurity Dashboard</Title>
                    <Form.Item
                        label="Label"
                        name="label"
                        className='cybersecurity-form-item'
                        rules={[
                            {
                                required: true,
                                message: 'Please enter label'
                            }
                        ]}
                    >
                        <Input
                            type="text" 
                            placeholder="Enter name of the dashboard you want to embed e.g NinjaOne" 
                            size="large"
                        />
                    </Form.Item> 
                    <Form.Item
                        label="iframe/URL"
                        name="iframe"
                        className='cybersecurity-form-item'
                        rules={[
                            {
                                required: true,
                                message: "Please enter an iframe code or URL",
                            },
                            {
                                validator: (_, value) => {
                                    const iframeRegex = /<iframe[^>]+src=["']([^"']+)["'][^>]*><\/iframe>/i;
                                    const urlRegex = /^(https?:\/\/[^\s$.?#].[^\s]*)$/i;
                    
                                    if (iframeRegex.test(value) || urlRegex.test(value)) {
                                        return Promise.resolve();
                                    }
                    
                                    return Promise.reject("Invalid input. Please enter a valid iframe code or URL.");
                                },
                            },
                        ]}
                        extra="We strongly recommend submitting an iframe code instead of a URL, as the browser often blocks direct URL embedding for security reasons."
                    >
                        <Input 
                            type="text" 
                            placeholder="Enter dashboard iframe code or URL" 
                            size="large"
                            maxLength={250}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Upload Icon"
                        className='cybersecurity-form-item'
                    >
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                    <div>
                        <Space align='center'>
                            <Text style={{fontWeight:500}}>Make private</Text>
                            <Tooltip title="You have the option to keep this dashboard private between you and your client. Otherwise, it will be accessible to other technicians who have a contract with the same client.">
                                <InfoCircleOutlined className='mt-2'/>
                            </Tooltip>
                        </Space>
                        <Form.Item
                            name="private"
                            valuePropName="checked"
                            className='cybersecurity-form-item'
                        >
                            <Switch />
                        </Form.Item>
                    </div>
                    <Form.Item className='cybersecurity-form-item mt-4 d-flex justify-content-end'>
                        <Space>
                            <Button 
                                className='itc-next-btn' 
                                type='secondary'
                                onClick={() => {
                                    setOpenSetUpModal(false)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button 
                                className='itc-next-btn' 
                                type='primary' 
                                htmlType='submit'
                                loading={submittingCybersecurity}
                            >
                                { editCybersecurityDetails ? 'Update' : 'Add' }
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <ActionModal
                visible={deleteModal}
                handleCancel={() => {
                    setDeleteModal(false)
                    form.resetFields()
                }}
                handleClick={() => deleteCybersecurity()}
                message={"This action will permanently delete the dashboard. Please make sure you want to delete the item before proceeding."}
                loading={deletingCybersecurity}
                deleteText={"Delete"}
            />
        </>             
    )
}

export default CybersecurityDetails