import React, {useEffect, useRef, useState} from 'react'
import { useSelector } from 'react-redux'
import { Button, Form, Input, Modal, Space, Typography } from 'antd'
import { ka } from 'date-fns/locale'

const {Paragraph, Title} = Typography
const { useForm } = Form

const ExternalIntegrations = ({handleNextClick, companyDetails, setCompanyDetails,  itcId, isFromItcProfile=false}) => {
    const tech = useSelector(state => state.tech)
    const [form] = useForm();
    
    const handleIntegrationSubmit = (values) => {
        setCompanyDetails({
            ...companyDetails,
            crm_form: values?.crmForm,
            kanban_board: values?.kanbanBoard,
        })
        handleNextClick()
    }

    useEffect(() => {
        form.setFieldsValue({
            crmForm: companyDetails?.crm_form,
            kanbanBoard: companyDetails?.kanban_board,
        })
    }, [companyDetails])

    return (
       <div className="integrations-section">
            <div className="integrations-ctn"> 
                <div className="integrations-form-ctn">
                    <Form
                        onFinish={handleIntegrationSubmit}
                        form={form}
                        layout='vertical'
                        className="integrations-form"
                    >
                        <ExternalIntegrationsForm isProject={companyDetails?.is_project} />
                        <Form.Item  className='integrations-form-item'>
                            <Space>
                                <Button className='itc-next-btn' type='primary' htmlType='submit'>Next</Button>
                                <Button 
                                        className='itc-next-btn' 
                                        type='secondary'
                                        onClick={() => {
                                            handleNextClick()
                                        }}
                                    >
                                        Skip
                                </Button>
                            </Space>
                        </Form.Item> 
                    </Form>
                </div>
            </div>
        </div>
    )
}

const IntegrationsHeading = () => {
    return(
        <>
            <Title level={4} className="itc-form-title">Zapier Integrations</Title>
            <Paragraph className="integration-subtitle">While we recommend using Zapier for building and integrating your interface, you are welcome to use any platform that offers similar services. If you need to integrate interfaces beyond those listed below, please reach out to us <a href="mailto:support@tecme.io" target='_blank' className='ext-link'>here</a> </Paragraph>
        </>
    )
}

export const ExternalIntegrationsForm = ({isProject}) => {
    const [openModal, setOpenModal] = useState(false)
    const videoRef = useRef(null);

    return(
        <>
            <IntegrationsHeading />
            <div>
                <Title level={5} style={{fontSize:14}} className="itc-form-title">Quidelines to get CRM form iframe via zapier and embed into your {isProject ? 'Project' : 'ITSC'} webpage</Title>
                <ol>
                    <li>Sign up or login to your <a className='ext-link' href='https://interfaces.zapier.com/interfaces?modalOpen=create' target='_blank'>Zapier account</a></li>
                    <li>Click on create interfaces</li>
                    <li>Click on a pre-existing template to build your form or Kanban board</li>
                    <li>After building your interface, click on the share button at the top right hand side of your zapier editor</li>
                    <li>Select the embed section and select code snippet type as iframe</li>
                    <li>Copy the code snippet and paste it in the text input below</li>
                    <li>To make changes to your interface, visit your Zapier interface editor</li>
                </ol>
                <Paragraph style={{fontSize:14}}>
                    Note: You can also use other form builders or project management tools that offers integration and embeddable forms and Kanban boards via iframe
                </Paragraph>
                <Paragraph className='mb-2' style={{fontSize:14}}>Click <span className='ext-link cursor-pointer' onClick={() => setOpenModal(true)}>here</span> to watch an explainer video</Paragraph>
            </div>
             <Form.Item  
                name="crmForm"  
                label="CRM Form" 
                className='integrations-form-item'
                extra="We'll use this iframe to embed your CRM form into your webpage"
                rules={[
                    {
                        pattern: /<iframe\b[^>]*>(.*?)<\/iframe>/g,
                        message: 'Please enter a valid iframe code'
                    }   
                ]}
            >
                <Input
                    type="text" 
                    placeholder="E.g <iframe src='https://interfaces.zapier.com/embed/page/cm4nobuqo000314ba5b206zxf?' style='max-width: 900px; width: 100%; height: 500px;'></iframe>" 
                    size="large"
                />
            </Form.Item> 
            <Form.Item  
                name="kanbanBoard"  
                label="Kanban Board" 
                className='integrations-form-item'
                extra="We'll use this iframe to embed your Kanban board into your dashboard. This will only be visible to your team members"
                rules={[
                    {
                        pattern: /<iframe\b[^>]*>(.*?)<\/iframe>/g,
                        message: 'Please enter a valid iframe code'
                    }   
                ]}
            >
                <Input
                    type="text" 
                    placeholder="E.g <iframe src='https://interfaces.zapier.com/embed/page/cm4nobuqo000314ba5b206zxf?' style='max-width: 900px; width: 100%; height: 500px;'></iframe>"  
                    size="large"
                />
            </Form.Item> 
            {/* <div>
                <Title level={5} style={{fontSize:14}} className="itc-form-title">Quidelines to get Kanban board iframe via Zapier and embed into your dashboard</Title>
                <ol>
                    <li>Sign up or login to your <a className='ext-link' href='https://interfaces.zapier.com/interfaces?modalOpen=create' target='_blank'>Zapier account</a></li>
                    <li>Click on create interfaces</li>
                    <li>Click on a pre-existing template to build your Kanban board or build from scratch</li>
                    <li>After building your board, click on the share button at the top right hand side of your zapier editor</li>
                    <li>Select the embed section and select code snippet type as iframe</li>
                    <li>Copy the code snippet and paste it in the Kanban board input field above</li>
                    <li>To make changes to your form, visit your Zapier interface editor</li>
                </ol>
                <Paragraph className='mb-2' style={{fontSize:14}}>Click <span className='ext-link cursor-pointer' onClick={() => setOpenModal(true)}>here</span> to watch an explainer video</Paragraph>
                <Paragraph style={{fontSize:14}}>
                    Note: You can also use other project management tools that offers integration and embeddable Kanban boards via iframe
                </Paragraph>
            </div> */}
            <Modal
                title="Explainer Video"
                centered
                open={openModal}
                onOk={() => setOpenModal(false)}
                onCancel={() => {
                    setOpenModal(false)
                    videoRef.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')}
                }
                width={700}
                footer={null}
            >
                <iframe 
                    ref={videoRef}
                    width="100%" 
                    height="450" 
                    src="https://www.youtube.com/embed/Nt2v-ju1nls?enablejsapi=1"
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
                </iframe>
            </Modal>
        </>
    )
}

export default ExternalIntegrations