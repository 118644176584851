import React, { useEffect, useState } from 'react'
import './assets/css/styles.scss'
import { META_DESCRIPTION, SECONDARY_COLOR } from '../../../../../utils/constants'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import { MetaTags } from 'react-meta-tags'
import ROUTES from '../../../../../utils/routes'
import { Avatar, Badge, Button, Col, Empty, Layout, List, notification, Row, Space, Typography} from 'antd'
import { useSelector } from 'react-redux'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useHistory, useParams, Link } from 'react-router-dom'
import ActiveUser from '../../../common/private/dashboard/components/active-user'
import PageFallback from '../../../../components/page-fallback'
import { EnvironmentOutlined, ExportOutlined, InfoCircleOutlined, WechatOutlined } from '@ant-design/icons'
import { addProjectManagementLinkLogo, addSubdomainToBaseUrl, capitalizeFirstLetter, createGroupChat, getAndSetGroupCoversation, getFromNowDateFormat, getIframeSrc } from '../../../../../utils/helper'
import MessagesRightPaneView from '../../../common/private/real-time-messaging/components/messages-right-pane-view'
import { FirebaseHelper, FirestoreHelper } from '../../../../../utils/firebase-helper'
import { getWorkTypeInfo } from '../../../common/private/job-post/utils/helper'
import RightArrow from '../../../../../assets/images/right-arrow.svg'
import JoinRequests from '../itc-profile/components/join-requests'
import { LoadingTechCards } from '../create-itc/components/tech-team'
import { Tooltip } from '@mui/material'
import { getAlgoliaIndex } from '../../../common/public/instant-search/utils/helper'
import axios from 'axios'
import ProjectTag from '../landing-page/components/project-tag'
import { dashboardSections } from '../create-itc/components/external-integrations-new'

const algoliaAppID =   process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const algoliaSearchKey =  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const algoliaIndex = getAlgoliaIndex();

const { Content } = Layout
const {Title, Paragraph, Text} = Typography

const ItcDashboard = () => {

    const tech = useSelector(state => state.tech)
    const [ loadingContracts, setLoadingContracts ] = useState(false)
    const [ contractData, setContractData ] = useState([])
    const [ ticketData, setTicketData ] = useState([])
    const [ loadingDetails, setLoadingDetails ] = useState(false)
    const [ itcDetails, setItcDetails ] = useState({})
    const [ loadingGroupConversation, setLoadingGroupConversation ] = useState(false)
    const [ groupConversations, setGroupConversations ] = useState(null)
    const [ creatingGroupChat, setCreatingGroupChat ] = useState(false)
    const [ firebaseError, setFirebaseError ] = useState("")
    const [techTeam, setTechTeam] = useState([])
    const [loadingTeam, setLoadingTeam] = useState(false)
    const [loadingTechProfiles, setLoadingTechProfiles] = useState(false)
    const [techProfiles, setTechProfiles] = useState([])
    const [activeTechs, setActiveTechs] = useState([])
    const [integrations, setIntegrations] = useState([])
    const [sections, setSections] = useState([])
    const [loadingIntegrations, setLoadingIntegrations] = useState(false)
    const iframeSrc = itcDetails ? getIframeSrc(itcDetails?.custom_data?.kanbanBoard) : ""
 
    const history = useHistory()
    const params = useParams()
    
    const getItcDetails = () => {
        setLoadingDetails(true)
        ApiBaseHelper.get({
            url: `itc/${params?.id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setItcDetails(res?.data?.data)
            setLoadingDetails(false)
        }).catch((error) => {
            console.error(error)
            setLoadingDetails(false)
        })       
    }

    const fetchContracts = () => {
        setLoadingContracts(true)
        ApiBaseHelper.get({
            url: `contract/itc-contracts?itc=${params?.id}`, headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            const sortedContractData =  [...res?.data.data]
            sortedContractData?.sort((a, b) => {
                const dateA = new Date(a.Active_Date_Time);
                const dateB = new Date(b.Active_Date_Time);
                return dateB - dateA;
            });
            setContractData(sortedContractData)
            setLoadingContracts(false)
        }).catch((error) => {
            console.error(error)
            setLoadingContracts(false)
        })       
    }

    const fetchTickets = () => {
        ApiBaseHelper.get({
            url: `ticket?role=${tech ? 'technician' : 'customer'}&itc=${params.id}`, headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            const sortedTicketData =  [...res?.data.data]
            sortedTicketData?.sort((a, b) => {
                const dateA = new Date(a.Active_Date_Time);
                const dateB = new Date(b.Active_Date_Time);
                return dateB - dateA;
            });
            setTicketData(sortedTicketData)
        }).catch((error) => {
            console.error(error)
        })       
    }

    const fetchTechTeam = () => {
        setLoadingTeam(true)
        ApiBaseHelper.get({
            url: `itc/member/all?itc=${params?.id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setTechTeam(res?.data?.data)
            setLoadingTeam(false)
        }).catch((error) => {
            console.error(error)
            setLoadingTeam(false)
        })       
    }

     // fetch tech profiles
     const fetchTechProfiles = async () => {
        setLoadingTechProfiles(true)
        try {
            const response = await axios.get(
              `https://${algoliaAppID}-dsn.algolia.net/1/indexes/${algoliaIndex}`,
              {
                headers: {
                  'X-Algolia-API-Key': algoliaSearchKey,
                  'X-Algolia-Application-Id': algoliaAppID,
                },
                params: {
                  hitsPerPage: 1000, 
                },
              }
            );
      
            // Extract and store the search results from the response
            setTechProfiles(response.data.hits);
            setLoadingTechProfiles(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingTechProfiles(false)
        }
    }

    // Get ITC conversations 
    const getConversations = async(companyName) => {
        try{
            setLoadingGroupConversation(true)
            const response = await getAndSetGroupCoversation(companyName)
            setGroupConversations(response)
            setLoadingGroupConversation(false)
        }catch(error){
            console.error(error)
            setFirebaseError(error)
            setLoadingGroupConversation(false)
        }
    }

    // get all user intefaces associated with itc
    const fetchIntegrations = () => {
        setLoadingIntegrations(true)
        ApiBaseHelper.get({
            url: `itc/itc-integration/all?itc=${params?.id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setIntegrations(res?.data?.data)
            setLoadingIntegrations(false)
        }).catch((error) => {
            console.error(error)
            setLoadingIntegrations(false)
        })       
    }

    // render functions on initial mount
    const initialRenders = () => {
        getItcDetails()
        fetchContracts()
        fetchTechTeam()
        fetchTechProfiles()
        fetchIntegrations()
        window.scrollTo(0, 0)
    }

    // manually create group chat
    const manualGroupChatCreate = async () => {
        try {
            setCreatingGroupChat(true);
            
            // Wait for group chat creation to complete
            await createGroupChat(itcDetails?.Company_Name, tech);
            
            // Add a small delay to ensure Firestore has updated
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            // Get the updated conversations
            await getConversations(itcDetails?.Company_Name);
            
            notification.success({
                message: 'TecMe',
                description: 'Group chat created'
            });
        } catch (error) {
            console.error(error);
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while creating group chat'
            });
        } finally {
            setCreatingGroupChat(false);
        }
    };
    

    useEffect(() => {
        setActiveTechs(techProfiles.filter((profile) => {
            return techTeam.some((member) => member.Member_Contact === profile.objectID && member.Status === "Active")
        }))
    },[techTeam, techProfiles])
   
    useEffect(() => {
        initialRenders()
    }, [])

    useEffect(() => {
        fetchTickets()
    }, [contractData])

    useEffect(async () => {
        if(itcDetails?.Company_Name){
            getConversations(itcDetails?.Company_Name)
        }
    }, [itcDetails])

    useEffect(() => {
        if(integrations){
            const updatedSections = dashboardSections.map((section) => {
                const sectionData = integrations.filter((ele) => ele?.Page === 'dashboard' && ele?.Page_Location === section)
                return ({
                    [section]: sectionData
                })
            })
            setSections(updatedSections)
        }
    }, [integrations])

    const statsData =  [
        {
            key: "contracts_assigned",
            title: 'Contracts Assigned',
            value: contractData.length || 0,
        },
        {
            key: "tickets_opened",
            title: 'Tickets Opened',
            value: ticketData.filter((ele) => (ele?.Status?.toLowerCase().includes("open")))?.length || 0,
        },
        {
            key: "tickets_closed",
            title: 'Tickets Closed',
            value: ticketData.filter((ele) => (ele?.Status?.toLowerCase().includes("closed")))?.length || 0,
        },
        {
            key: "total_hours_worked",
            title: 'Total Hours Worked',
            value: ticketData.reduce((acc, item) => {
                if(item?.custom_data?.duration === undefined) return acc
                return  acc + Math.round(Number(item?.custom_data?.duration)/60)
            }, 0) || 0,
        },
        {
            key: "contracts_ended",
            title: 'Contracts Ended',
            value: contractData.filter((ele) => (ele?.Status?.toLowerCase().includes("terminated")))?.length || 0,
        },
        {
            key: "total_technicians",
            title: 'Total Technicians',
            value: activeTechs?.length || 0,
        },
    ]

    const getStatsInfo = (key) => {
        switch (key) {
            case "contracts_assigned":
                return `Total number of contracts assigned to ${itcDetails?.Company_Name}`
            case "tickets_opened":
                return "Total number of tickets opened by you"
            case "tickets_closed":
                return "Total number of tickets closed by you"
            case "total_hours_worked":
                return `Total number of hours you've worked for ${itcDetails?.Company_Name}`
            case "contracts_ended":
                return `Total number of contracts ended in ${itcDetails?.Company_Name}`
            case "total_technicians":
                return `Total number of technicians in ${itcDetails?.Company_Name}`
            default:
                return ""
        }
    }

    if(loadingDetails || loadingContracts || loadingGroupConversation){
        return <PageFallback />
    }

    return (
        <>
            <MetaTags>
                <title> ITSC Dashboard | TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow"/>
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.ITC_PROFILES,  
                        breadcrumbName: 'ITSC Profiles',
                    },
                    { 
                        breadcrumbName: capitalizeFirstLetter(itcDetails?.Company_Name),
                    },
                ]}
            />
            <Content className="main">
                <div className='page'>
                    <div className="auth-container">
                        <div className="auth-page-ctn itc-dashboard-ctn">
                            <div className="itc-dashboard-header">
                                <div className="dashboard-welcome-section">
                                    <div>
                                        <Space align='center' wrap>
                                            <Title level={3} style={{marginBottom:0, color:"#000", fontSize:20}}>Welcome to {capitalizeFirstLetter(itcDetails?.Company_Name)}</Title>
                                            <a href={addSubdomainToBaseUrl(itcDetails?.Subdomain)} target="_blank" rel="noopener noreferrer">  
                                            {
                                                addSubdomainToBaseUrl(itcDetails?.Subdomain).includes('http') &&
                                                addSubdomainToBaseUrl(itcDetails?.Subdomain).replace('https://', 'www.').replace('http://', 'www.')
                                            } 
                                            <ExportOutlined style={{color:SECONDARY_COLOR, fontSize:14, marginLeft:5}}/></a>
                                            {
                                                itcDetails?.isProject === "1" &&
                                                <ProjectTag/>
                                            }
                                        </Space>
                                        <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", margin:0}}>Here you can access and track all your company IT activities, metrics & needs.</Paragraph>
                                    </div>
                                    {
                                        itcDetails?.Owner_Contact === tech?.id &&
                                        <Button 
                                            type="primary"
                                            style={{width:122}}
                                            onClick={(e) => {
                                                history.push(ROUTES.ITC_PROFILE.replace(':id', params?.id))  
                                                e.stopPropagation()
                                            }}
                                        >
                                            Edit {itcDetails?.isProject === "1" ? "Project" : "ITSC"}
                                        </Button>
                                    }
                                </div>
                                <div className="itc-dashboard-stats">
                                    <Row gutter={[16, 16]}>
                                        {
                                            statsData.map((item) => (
                                                <Col xs={24} sm={12} md={8} lg={4} xl={4} key={item.key}>
                                                    <div className="itc-dashboard-stats-item">
                                                        <div className="itc-dashboard-stats-item-content">
                                                            <Title level={4} style={{marginBottom:8}}>{item.value}</Title>
                                                            <Paragraph style={{margin:0, fontSize:14, color:"rgba(0, 0, 0, 0.65)"}}>{item.title} 
                                                            <Tooltip title={getStatsInfo(item.key)}>
                                                                <InfoCircleOutlined className='stats-info-icon'/> 
                                                            </Tooltip>
                                                            </Paragraph>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                                {/* Dashboard Upper Section */}
                                {
                                    sections?.find(item => item.upper)?.upper?.map((section) => {
                                        return(
                                            <div className="dashboard-section-iframe">
                                                <iframe src={getIframeSrc(section.iframe)} style={{ width: "100%", minHeight: "90vh"}}></iframe>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="itc-dashboard-content">
                                <div className="contract-ticket-section">
                                    <ActiveUser
                                        contractData={contractData}
                                        ticketData={ticketData}
                                        loadingContracts={loadingContracts}
                                        client={null}
                                        tech={tech}
                                        isFromDashboard={false}
                                        itcDetails={itcDetails}
                                    />
                                </div>
                                <div className="message-job-section">
                                    <Row gutter={[24, 24]}>
                                        <Col xs={24} sm={24} md={24} xl={16}>
                                            {
                                                groupConversations ? 
                                                <MessagesRightPaneView
                                                    key={`messages-${groupConversations.id}`}
                                                    conversation={groupConversations}
                                                    user={tech}
                                                    chatBoxStatus={true}
                                                    serviceDetails={true}
                                                    contractPage
                                                    onClickBack={() => ''}
                                                    isTech={true}
                                                    isFromItc={true}
                                                    // isContractPaused={contractDetails.Status === contractStatuses.Paused}
                                                /> :
                                                <div className="no-group-conversation">
                                                    {
                                                        firebaseError ? 
                                                        <div style={{textAlign:'center'}}>
                                                            <p style={{fontStyle:"italic"}}>Seems like there was an error while fetching your company group chat. Kindly check your network connection or reach out to our support</p>
                                                        </div> :
                                                        <div style={{textAlign:'center'}}>
                                                            <p>Seems like your company group chat wasn't automatically created</p>
                                                            <Button
                                                                onClick={() => manualGroupChatCreate()}
                                                                loading={creatingGroupChat}
                                                            >
                                                                Create Group Chat
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </Col>
                                        <Col xs={24} sm={24} md={24} xl={8}>
                                            <div className="tech-team-content" style={{height:"100%"}}>
                                                <div className="tech-team-heading">
                                                    <div>
                                                        <Title level={4} style={{marginBottom:12, color:"#000"}}>Team Members</Title>
                                                        <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>List of all your current active team members</Paragraph> 
                                                    </div>
                                                    {  
                                                        itcDetails?.Owner_Contact === tech?.id &&
                                                        <Link to={ROUTES.ITC_TECH_TEAM.replace(':id', params?.id)} className="view-all">Manage Team</Link>
                                                    }
                                                </div>
                                                <div className="tech-team-listings">
                                                   {
                                                        <List
                                                            itemLayout='vertical'
                                                            dataSource={activeTechs}
                                                            renderItem={item => (
                                                                <List.Item key={item.objectID}>
                                                                    <Space size={12}>
                                                                        <Avatar 
                                                                            size={74} 
                                                                            src={item?.image}
                                                                            shape='square'
                                                                            style={{color:SECONDARY_COLOR}}
                                                                        >
                                                                            {item?.name?.split(" ")[0][0]}{item?.name?.split(" ")[1][0].toUpperCase()}
                                                                        </Avatar>
                                                                        <div className="tech-team-tech-details">
                                                                            <Space className='d-flex'>
                                                                                <Paragraph style={{margin:0, color:"#000", fontSize:18, fontWeight:500}}>{item?.name}</Paragraph>
                                                                                <Badge status={itcDetails?.Owner_Contact === item?.objectID ? "success" : "default"} text={itcDetails?.Owner_Contact === item?.objectID ? "Owner" : `Member`} />
                                                                            </Space>
                                                                            <Space size={3}>
                                                                                <EnvironmentOutlined style={{marginTop:4}}/>
                                                                                <Paragraph  style={{margin:0, color:"#000"}}>{item?.city}{item?.state ? `, ${item?.state}` : ""}</Paragraph>
                                                                            </Space>
                                                                            <Link className="view-link" to={ROUTES.TECH_PROFILE_WITH_ID.replace(":id", `${item.objectID}`)}>View Profile</Link>
                                                                        </div>
                                                                    </Space>
                                                                </List.Item>
                                                            )}
                                                        />
                                                   }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 24]} style={{paddingBottom:40}}>
                                        {
                                            itcDetails?.Project_Links !== null &&
                                            <Col xs={24} sm={24} md={24} lg={8} xl={8} >
                                                <div style={{padding:30}} className="project-management-links-section">
                                                    <div className="project-management-links-heading">
                                                        <Title level={4} style={{ marginBottom: 12, color: "#000" }}>Project Management Links</Title>
                                                        <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>This list contains external project management links for managing this {itcDetails?.isProject === "1" ? "Project" : "ITSC"}</Paragraph> 
                                                    </div>
                                                    <div>
                                                        {
                                                           itcDetails.Project_Links?.split(",").map((link, index) => (
                                                                <div key={index} className="project-management-link">
                                                                    <Space size={12}>
                                                                        <Avatar 
                                                                            size={54} 
                                                                            src={addProjectManagementLinkLogo(link)?.logo || null}
                                                                            shape='square'
                                                                            style={{color:'#fff', border:"1px solid #D9D9D9", borderRadius:4, padding:5, backgroundColor: addProjectManagementLinkLogo(link)?.logo ? '#fff' : '#000', display:'flex', justifyContent:'center', alignItems:'center', fontSize:30}}
                                                                        >
                                                                            {addProjectManagementLinkLogo(link)?.name?.charAt(0).toUpperCase()}
                                                                        </Avatar>
                                                                        <div>
                                                                            <Paragraph style={{marginBottom:5, color:"#000", fontSize:16, fontWeight:500}}>{capitalizeFirstLetter(addProjectManagementLinkLogo(link)?.name)}</Paragraph>
                                                                            <a className="view-link" href={link} target="_blank" rel="noopener noreferrer">View Project</a>
                                                                        </div>
                                                                    </Space>
                                                                </div>
                                                            )) 
                                                        }
                                                    </div>
                                                </div> 
                                            </Col>
                                        }
                                        {
                                            itcDetails?.Owner_Contact === tech?.id &&
                                            <Col xs={24} sm={24} md={24} lg={itcDetails?.Project_Links === null ? 24 : 16} xl={itcDetails?.Project_Links === null ? 24 : 16}>
                                                <div className="join-request-section" style={{height:"100%"}}>
                                                    <div className="join-request-heading">
                                                        <Title level={4} style={{marginBottom:12, color:"#000"}}>Join Requests</Title>
                                                        { 
                                                            loadingTeam ?  <LoadingTechCards/> : 
                                                            <JoinRequests 
                                                                itcDetails={itcDetails}
                                                                fetchTechTeam={fetchTechTeam}
                                                                techTeam={techTeam}
                                                            />
                                                        }
                                                    </div>  
                                                </div>  
                                            </Col>       
                                        }   
                                    </Row>  
                                    {/* Dashboard Lower Section */}
                                    {
                                    sections?.find(item => item.lower)?.lower?.map((section) => {
                                        return(
                                            <div className="dashboard-section-iframe">
                                                <iframe src={getIframeSrc(section.iframe)} style={{ width: "100%", minHeight: "90vh"}}></iframe>
                                            </div>
                                        )
                                    })
                                    }     
                                </div>
                            </div>     
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
}

export default ItcDashboard