import React, { useEffect, useState } from 'react'
import { AddedItemCard } from '../../create-itc/components/added-item'
import { Avatar, Button, Modal, Space, Typography, notification } from 'antd'
import { FaqForm } from '../../create-itc/components/faq'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { LoadingOutlined } from '@ant-design/icons'
import FaqList from '../../create-itc/components/faq-list'
import HiddenBanner from './hidden-banner'

const { Title, Paragraph } = Typography

const ProfileFaq = ({itcId, itcDetails, getItcDetails, token}) => {
    const [showAddedFaq, setShowAddedFaq] = useState(false)
    const [faqs, setFaqs] = useState([])
    const [loadingFaqs, setLoadingFaqs] = useState(false)
    const [editFaqData, setEditFaqData] = useState(null)
    const [submittingDetails, setSubmittingDetails] = useState(false)
   
    const fetchFaqs = () => {
        setLoadingFaqs(true)
        ApiBaseHelper.get({
            url: `itc/faq/all?itc=${itcId}`, 
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setFaqs(res?.data?.data)
            setLoadingFaqs(false)
        }).catch((error) => {
            console.error(error)
            setLoadingFaqs(false)
        })       
    }
    console.log(itcDetails?.custom_data.hiddenSections?.split(","))
     // Enable faqs
     const handleEnableFaq = () => {
        setSubmittingDetails(true)
        const isIncludeSection = itcDetails?.custom_data?.hiddenSections?.split(",").includes('faqs')
        ApiBaseHelper.put({
            url: `itc/${itcId}`,	
            headers: {
                Authorization: 'Bearer ' + token,
            },
            payload: {
                Company_Name: itcDetails?.Company_Name,
                Hidden_Sections: isIncludeSection 
                    ? itcDetails?.custom_data.hiddenSections?.split(",").filter((section) => section !== 'faqs').filter(Boolean).join(",") 
                    : [...(itcDetails?.custom_data.hiddenSections?.split(",").filter(Boolean) || []), 'faqs'].join(",")
            }
        }).then((res) => {
            setSubmittingDetails(false)
            notification.success({
                message: 'TecMe',
                description: `FAQ section is now ${isIncludeSection ? 'visible' : 'hidden'} on webpage`
            })
            getItcDetails()
        }).catch((err) => {
            console.error(err)
            setSubmittingDetails(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occurred'
            })
        })
    }

    useEffect(() => {
        fetchFaqs()
    }, [])

    useEffect(() => {
        fetchFaqs()
    }, [showAddedFaq])


    if(showAddedFaq) {
        return(
            <div className="profile-faq-form-ctn">
               <FaqForm
                    setShowAddedFaq={setShowAddedFaq}
                    isFromItcProfile={true}
                    fetchFaqs={fetchFaqs}
                    editFaqData={editFaqData}
                    setEditFaqData={setEditFaqData}
               />
            </div>
        )
    }

    return (
        <div>
            {
                (!showAddedFaq && faqs.length > 0) &&
                <HiddenBanner 
                    text={itcDetails?.custom_data?.hiddenSections?.split(",").includes('faqs') ? "The FAQ section will not be displayed on your webpage because you've hidden it." : "If you don't want the FAQ section displayed on your webpage, you can hide it"}
                    hidden={!!itcDetails?.custom_data?.hiddenSections?.split(",").includes('faqs')}
                    loading={submittingDetails} 
                    handleClick={handleEnableFaq}
                />
            }
            {
                showAddedFaq ?
                <div className="profile-faq-form-ctn">
                <FaqForm
                        setShowAddedFaq={setShowAddedFaq}
                        isFromItcProfile={true}
                        fetchFaqs={fetchFaqs}
                        editFaqData={editFaqData}
                        setEditFaqData={setEditFaqData}
                />
                </div> :
                <>
                    <Title level={4} className="itc-form-title">FAQs</Title>
                    <FaqList
                        faqs={faqs} 
                        setShowAddedFaq={setShowAddedFaq}
                        fetchFaqs={fetchFaqs}
                        isFromItcProfile={true}
                        loadingFaqs={loadingFaqs}
                        setEditFaqData={setEditFaqData}
                    />
                </>
            }
        </div>
    )
}


export default ProfileFaq