import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ROUTES from '../../../../../../utils/routes'
import { Avatar, Button, Col, Pagination, Row, Space, Tag, Tooltip, Typography } from 'antd'
import { capitalizeFirstLetter, convertCentsIntoDollarsString, getDottedTimeFormat, getTableTimeFormat } from '../../../../../../utils/helper'
import { PlusOutlined } from '@ant-design/icons'
import NewSupportTicketModal from '../../contracts/contract-details/components/new-support-ticket-modal'
import AssignedTicketsContent from '../../contracts/contract-details/components/assigned-tickets-content'
import { ContractRepository } from '../../contracts/contract-details/repository'
import { useHistory } from 'react-router-dom'
import ActiveClients from './active-clients'

const {Title, Paragraph} = Typography

const contractTypes = [
    {
        id: "all",
        contract_type: "All",
        hint:""
    },
    {
        id: "draft",
        contract_type: "Draft",
        hint:"Contract proposals awaiting technician’s acceptance."
    },
    {
        id: "active",
        contract_type: "Active",
        hint:"Scheduled contracts currently in progress."
    },
    {
        id: "paused",
        contract_type: "Paused",
        hint: "Contains contracts that are temporarily paused by the customer for further service"
    },
    {
        id: "terminated",
        contract_type: "Ended",
        hint:"Contains contracts that are successfully completed, withdrawn by customer or rejected by the technician."
    },
]

const ActiveUser = ({contractData, ticketData, loadingContracts, client, tech, isFromDashboard, itcDetails=null}) => {

    const [isNewSupportTicketModalVisible, setIsNewSupportTicketModalVisible] = useState(false)
    const history = useHistory()
    const authUser = client || tech
    const isItcDashboardPage = window.location.pathname.includes('itsc/dashboard')
    const [ contractStatus, setContractStatus ] = useState("all")
    const [ filteredContracts, setFilteredContracts ] = useState(contractData)
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 8; 
    
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = contractData.slice(indexOfFirstItem, indexOfLastItem);

    const getBillingCycle = (contract) => {
        let billingCycle = '/hr'
        if(contract.Type === 'Fixed') {
            switch (contract.Billing_Frequency) {
                case "Monthly":
                    billingCycle = '/month'
                    break;
                case "Weekly":
                    billingCycle = '/week'
                    break;
                case "Bi-weekly":
                    billingCycle = ' bi-weekly'
                    break;
                default:
                    break;
            }
        }

        return billingCycle
    }

    const handleNewSupportTicketButtonClick = () => {
        setIsNewSupportTicketModalVisible(true)
    }

    const filterByStatus = () => {
        let filterResults =  contractData.filter((ele) => (ele?.Status?.toLowerCase().includes(contractStatus?.toLowerCase())))
        setFilteredContracts(filterResults)
        if(contractStatus == "" || contractStatus === "all"){
            setFilteredContracts(contractData)
        }
    }

    useEffect(() => {
        if(!isFromDashboard){
            filterByStatus()
        }
    }, [contractStatus])

    return (
        <div className={`active-user-ctn ${!isFromDashboard && 'itc-active-user-ctn'}`}>
            {
                (contractData?.length !== 0 && tech) &&
                <ActiveClients contractData={contractData}/>
            }
            {
                (contractData?.length !== 0 && (client || tech && isFromDashboard || (tech && isItcDashboardPage && tech?.id === itcDetails?.Owner_Contact))) &&
                <div className="active-user-content">
                    <div className="active-user-heading">
                        <div>
                            <Title level={4} style={{marginBottom:20, color:"#000"}}>{ isFromDashboard  && "Active"} Contracts</Title>
                            <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>Track the progress, tickets, and deliverables of your active projects, ensuring transparency and accountability.</Paragraph> 
                        </div>
                        {isFromDashboard && <Link to={ROUTES.CONTRACTS} className="view-all">Show all</Link>}
                    </div>
                    {
                        !isFromDashboard &&
                        <div className='contract-type-list'>
                            {
                                contractTypes.map((item) => {
                                    return(
                                        <Tooltip placement="top" title={item.hint}>
                                            <div key={item?.id} className={`contract-type-item ${item?.id == contractStatus && 'contract-type-item-active'}`}
                                            onClick={() => {
                                                setContractStatus(item?.id)
                                            }}
                                            >
                                                {item.contract_type}
                                            </div>
                                        </Tooltip>
                                    )
                                })
                            }
                        </div>
                    }
                    <Row className='contract-cards' gutter={[28, 28]}>
                        {
                            ( isFromDashboard ? contractData?.slice(0, 4) : filteredContracts)?.map((item, index) => (
                                <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                                    <div 
                                        className="contract-card" 
                                        key={index} 
                                        onClick={() => history?.push(ROUTES.VIEW_EDIT_CONTRACT.replace(':contractId', `${item.id}`))}
                                    >
                                        <div className="contract-card-provider">
                                            <Space size={16}>
                                                <Avatar 
                                                    src={client ? item?.Provider_Org?.Contact_Image : item?.Customer_Org?.Logo} 
                                                    alt={client ? (item?.Provider_Org?.HIPAA_Contact_Name || item?.Provider_Org?.Name) : item?.Customer_Org?.Name} size={64}>
                                                        {client ? (item?.Provider_Org?.HIPAA_Contact_Name ? item?.Provider_Org?.HIPAA_Contact_Name?.[0]?.toUpperCase() : item?.Provider_Org?.Name?.[0]?.toUpperCase()) : item?.Customer_Org?.Name?.[0]?.toUpperCase()}
                                                </Avatar>
                                                <Space size={0} direction="vertical">
                                                    <Title level={5} style={{marginBottom:0}}>{client ? (item?.Provider_Org?.HIPAA_Contact_Name || item?.Provider_Org?.Name) : item?.Customer_Org?.Name}</Title>
                                                {
                                                client && 
                                                <Link onClick={(e) => e.stopPropagation()} to={ROUTES.TECH_PROFILE_WITH_ID.replace(':id', item?.Provider_Org?.HIPAA_Primary_Contact)} className="view-profile" target="_blank" rel="noopener noreferrer" >View Profile</Link>
                                                }
                                                </Space>
                                            </Space>
                                        </div>
                                        <div className="contract-card-content">
                                            <Paragraph ellipsis={{rows:1}}>{capitalizeFirstLetter(item?.Description)}</Paragraph>
                                            <Paragraph>${convertCentsIntoDollarsString(Number(item.Billing_Rate))}{getBillingCycle(item)}</Paragraph>
                                            <div>
                                                <Tag>{item?.Status}</Tag>
                                            </div>
                                            <Space className='mt-3' size={30}>
                                                <Paragraph style={{margin:0, color:"#7C8493", fontSize:14, fontWeight:400}}>Started on</Paragraph>
                                                <Paragraph style={{margin:0}}>{getDottedTimeFormat(item?.Active_Date_Time)}</Paragraph>
                                            </Space>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    {
                        (!isFromDashboard && !!filteredContracts.length) &&
                        <div className='itc-contract-pagination'>
                            <Pagination
                                current={currentPage}
                                onChange={(page) => setCurrentPage(page)}
                                total={contractData.length}
                                pageSize={pageSize}
                                // onShowSizeChange={(current, size) => setPageSize(size)}
                            />
                        </div>
                    }
                </div>
            }
           { 
            ticketData?.length !== 0 &&
            <>
                <div className="active-user-content">
                    <div className="active-user-heading">
                        {
                            isFromDashboard && 
                            <div>
                                <Space size={20} style={{marginBottom:20}} wrap>
                                    <Title level={4} style={{marginBottom:0, color:"#000"}}>Active Tickets</Title>
                                { !!client &&
                                    <Button type="secondary" className="create-ticket-btn" icon={<PlusOutlined />} onClick={() => setIsNewSupportTicketModalVisible(true)}>Create New</Button>
                                    }
                                </Space>
                                <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14}}> Keep track of your support and service tickets, allowing you to efficiently manage and prioritize IT-related issues, ensuring prompt resolution and minimizing downtime.</Paragraph> 
                            </div>
                        }
                        { 
                            !isItcDashboardPage && 
                            <Link to={ROUTES.TICKET_LISTING} className="view-all">Show all</Link>
                        }
                    </div>
                    <div className="tickets-table">
                        <AssignedTicketsContent 
                            handleNewSupportTicketButtonClick={handleNewSupportTicketButtonClick} 
                            isFromDashboard={isFromDashboard}
                            itcTickets={ticketData}
                        />
                    </div>
                </div>
                <NewSupportTicketModal
                    user={client}
                    tech={tech}
                    repository={new ContractRepository(authUser?.token)}
                    visible={isNewSupportTicketModalVisible}
                    onOk={() => setIsNewSupportTicketModalVisible(false)}
                    onCancel={() => setIsNewSupportTicketModalVisible(false)}
                />
            </>
            }
        </div>
    )
}

export default ActiveUser