import React, { useEffect, useState } from 'react'
import { CustomizationForm } from '../../create-itc/components/customization'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { Button, notification } from 'antd'
import { isValidUrl } from '../../../../../../utils/helper'

const ProfileCustomization = ({itcDetails, itcId, getItcDetails, token}) => {
    const [companyDetails, setCompanyDetails] = useState({
        brand_color: `#${itcDetails?.Brand_Color}`,
        brand_logo: itcDetails?.Brand,
        hidden_sections: itcDetails?.custom_data?.hiddenSections
    })
    const [submittingDetails, setSubmittingDetails] = useState(false)
    const [logoFile, setLogoFile] = useState()

    const handleCustomizationSubmit = () => {
        console.log(companyDetails)
        setSubmittingDetails(true)
        let payload = {
            Brand_Color: companyDetails?.brand_color?.replace('#', ''),
            Hidden_Sections: companyDetails?.hidden_sections
        }
        ApiBaseHelper.put({
            url: `itc/${itcId}`,	
            headers: {
                Authorization: 'Bearer ' + token,
            },
            payload: !logoFile ?  {
                ...payload,
                Brand: null
            } : payload
        }).then((res) => {
            if(logoFile && isValidUrl(logoFile) === false){
                handleFileSubmit(itcId)
            }
            setSubmittingDetails(false)
            notification.success({
                message: 'TecMe',
                description: 'Company details updated successfully'
            })
            getItcDetails()
        }).catch((err) => {
            setSubmittingDetails(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while updating company details'
            })
        })
    }

    const handleFileSubmit = (id) => {
        const payload = new FormData();
        payload.append('file', logoFile)
        ApiBaseHelper.post({
            url: `itc/logo/${id}`,	
            headers: {
                Authorization: 'Bearer ' + token,
            },
            payload: payload
        }).then((res) => {
            setSubmittingDetails(false)
        }).catch((err) => {
            setSubmittingDetails(false)
            console.log(err)
        })
    }

    useEffect(() => {
        if(itcDetails){
            setLogoFile(itcDetails?.Brand)
        }
    }, [])

    return (
        <div className='customization-form-ctn'>
            <CustomizationForm 
                companyDetails={companyDetails}
                setCompanyDetails={setCompanyDetails}
                setLogoFile={setLogoFile}
                isFromItcProfile={true}
            />
            <Button
                className='itc-next-btn' 
                type='primary' 
                loading={submittingDetails}
                onClick={() => handleCustomizationSubmit()}
            >Update</Button>
        </div>
    )
}

export default ProfileCustomization