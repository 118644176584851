import { Button, Card, Form, Input, List, Modal, notification, Radio, Select, Space, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import CustomRenderEmpty from '../../../../../components/custom-render-empty'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { cleanIframeContent, getIframeSrc } from '../../../../../../utils/helper'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import ActionModal from './delete-modal'

const {Paragraph, Title} = Typography

export const webpageSections = ['about', 'specializations', 'processes', 'professionals', 'testimonials', 'FAQs']
export const dashboardSections = ['upper', 'lower']

const uiSourceOptions = [
    {
        value: 'airtable',
        label: 'Airtable',
    },
    {
        value: 'zapier',
        label: 'Zapier',
    },
    {
        value: 'google',
        label: 'Google',
    },
    {
        value: 'trello',
        label: 'Trello',
    },
    {
        value: 'other',
        label: 'Other',
    }
]


const ExternalIntegrationsNew = ({handleNextClick, itcId, isFromItcProfile=false}) => {
    const tech = useSelector(state => state.tech)
    const [openInterfaceModal, setOpenInterfaceModal] = useState(false)
    const [selectedPage, setSelectedPage] = useState('')
    const [pageSections, setPageSections] = useState([])
    const [submittingIntegration, setSubmittingIntegration] = useState(false)   
    const [loadingIntegrations, setLoadingIntegrations] = useState(false)
    const [integrations, setIntegrations] = useState([])
    const [selectedIntegration, setSelectedIntegration] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deletingIntegration, setDeletingIntegration] = useState(false)
    const [form] = Form.useForm();
    const param = useParams()

    const fetchIntegrations = () => {
        setLoadingIntegrations(true)
        ApiBaseHelper.get({
            url: `itc/itc-integration/all?itc=${itcId}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setIntegrations(res?.data?.data)
            setLoadingIntegrations(false)
        }).catch((error) => {
            console.error(error)
            setLoadingIntegrations(false)
        })       
    }

    const handleIntegrationSubmit = (values) => {
        setSubmittingIntegration(true)
        if(selectedIntegration){
            ApiBaseHelper.put({
                url: `itc/itc-integration/${selectedIntegration?.id}`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload: {
                    Description: values.description,
                    Page_Location: values.pageLocation,
                    Page: values.page,
                    Source: values.source,
                    iframe: cleanIframeContent(values.iframe),
                }
            }).then(async (res) => {
                setSubmittingIntegration(false)
                notification.success({
                    message: 'TecMe',
                    description: 'User interface updated successfully'
                })
                setOpenInterfaceModal(false)
                form.resetFields()
                setSelectedPage('')
                fetchIntegrations()
            }).catch((err) => {
                setSubmittingIntegration(false)
                notification.error({
                    message: 'TecMe',
                    description: 'An error occurred while updating user interface'
                })
                console.log(err)
            })
        }else{
            ApiBaseHelper.post({
                url: `itc/itc-integration`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload: {
                    Description: values.description ? values.description : "",
                    Page_Location: values.pageLocation,
                    Page: values.page,
                    Source: values.source,
                    iframe: cleanIframeContent(values.iframe),
                    ITC:isFromItcProfile ? param.id : itcId
                }
            }).then(async (res) => {
                setSubmittingIntegration(false)
                notification.success({
                    message: 'TecMe',
                    description: 'User interface added successfully'
                })
                setOpenInterfaceModal(false)
                form.resetFields()
                setSelectedPage('')
                fetchIntegrations()
            }).catch((err) => {
                setSubmittingIntegration(false)
                notification.error({
                    message: 'TecMe',
                    description: 'An error occurred while adding user interface'
                })
                console.log(err)
            })
        }
    }

    const deleteIntegration = () => {
        setDeletingIntegration(true)
        ApiBaseHelper.delete({
            url: `itc/itc-integration/${selectedIntegration?.id}`,
            headers: {
                Authorization: `Bearer ${tech.token}`
            },
        }).then((res) => {
            if (res.data) {
                notification['success']({
                    message: 'TecMe',
                    description: "User Interface deleted successfully",
                });
                fetchIntegrations()
                setDeleteModal(false)
                form.resetFields()
                setSelectedPage('')
                setDeletingIntegration(false)
            }
        }).catch((error) => {
            setDeletingIntegration(false)
            notification['error']({
                message: 'TecMe',
                description: "An error Occurred",
            });
        })
    }

    useEffect(() => {
        fetchIntegrations()
    }, [itcId])

    useEffect(() => {
        if(selectedPage === 'webpage'){
            setPageSections(webpageSections)
        } else if(selectedPage === 'dashboard'){
            setPageSections(dashboardSections)
        }
    }, [selectedPage])

    useEffect(() => {
        if(selectedIntegration){
            form.setFieldsValue({
                iframe: selectedIntegration.iframe,
                pageLocation: selectedIntegration.Page_Location,
                page: selectedIntegration?.Page,
                source: selectedIntegration?.Source,
                description: selectedIntegration?.Description
            })
            setSelectedPage(selectedIntegration?.Page)
        }

    }, [selectedIntegration])

   
    return (
        <div className="integrations-section">
            <div className="integrations-ctn">
                <div>
                    <Space align='center' className='mb-3' wrap>
                        <Title level={4} className="itc-form-title mb-0">Embed User Interfaces</Title>
                        <Button 
                            type='secondary'
                            onClick={() => setOpenInterfaceModal(true)}
                        >
                            + Add User Interface
                        </Button>
                    </Space>
                    <Paragraph className="integration-subtitle">
                        In a bid to help you extend the functionality of your ITSC, we allow you to embed user interfaces via <i><b>iframes</b></i> from other platforms. You can bring all your tools together in one place, and give your clients and team members a seamless experience. If you need more information on how to embed interfaces, please reach out to us <a href="mailto:support@tecme.io" target='_blank' className='ext-link'>here</a> 
                    </Paragraph>
                </div>
                <div>
                    <CustomRenderEmpty
                        description={"No user interfaces added yet"}
                    >
                        <List
                            grid={{ gutter: 16,  xs: 1,
                                sm: 2,
                                md: 2,
                                lg: 3,
                                xl: 3,
                                xxl: 3, 
                            }}
                            // pagination={{
                            //     pageSize: 8,
                            // }}
                            loading={loadingIntegrations}
                            dataSource={integrations}
                            renderItem={item => (
                                <List.Item>
                                    <Card
                                        cover={
                                            <iframe src={getIframeSrc(item.iframe)} style={{ width: "100%"}}></iframe>
                                        }
                                        className='p-0 integration-card'
                                    >
                                        <div>
                                            <Paragraph className="integration-subtitle mb-0">Embedded</Paragraph>
                                            <Paragraph style={{fontWeight:500}} className='mb-2'>On the <i>{item.Page}</i> {item.Page === 'webpage' ? `below the ${item.Page_Location} section` : `on the ${item.Page_Location} section`} </Paragraph>
                                        </div>
                                        {
                                            item.Source &&
                                            <div>
                                                <Paragraph className="integration-subtitle mb-0">Source</Paragraph>
                                                <Paragraph style={{fontWeight:500}} className='mb-2'>{item.Source}</Paragraph>
                                            </div>
                                        }
                                        {
                                            item.Description &&
                                            <div>
                                                <Paragraph className="integration-subtitle mb-0">Helper Text</Paragraph>
                                                <Paragraph style={{fontWeight:500}} className='mb-2'>{item.Description}</Paragraph>
                                            </div>
                                       }
                                        <Space size={14} className='d-flex justify-content-end'>
                                            <Tooltip title="Edit">
                                                <EditOutlined 
                                                    className='edit-outlined' 
                                                    onClick={() => {
                                                        setSelectedIntegration(item)
                                                        setOpenInterfaceModal(true)
                                                    }} 
                                                />
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <DeleteOutlined 
                                                    className='close-outlined' 
                                                    onClick={() => {
                                                        setSelectedIntegration(item)
                                                        setDeleteModal(true)
                                                    }} 
                                                />
                                            </Tooltip>
                                        </Space>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </CustomRenderEmpty>
                </div>
                {
                    !isFromItcProfile &&
                    <Space className='mt-4'>
                        <Button
                            className='itc-next-btn' 
                            type='primary' 
                            onClick={() => {
                                handleNextClick()
                            }}
                            disabled={!integrations.length}
                        >   
                            Next
                        </Button>
                        {
                            !integrations.length &&
                            <Button 
                                className='itc-next-btn' 
                                type='secondary'
                                onClick={() => {
                                    handleNextClick()
                                }}
                            >
                                Skip
                            </Button>
                        }
                    </Space>
                }
            </div>
            {/* Add/Edit User Interface Modal */}
            <Modal
                // title="Modal 1000px width"
                centered
                open={openInterfaceModal}
                onOk={() => {
                    setOpenInterfaceModal(false)
                    setSelectedIntegration(null)
                    form.resetFields()
                    setSelectedPage('')
                }}
                onCancel={() => {
                    setOpenInterfaceModal(false)
                    setSelectedIntegration(null)
                    form.resetFields()
                    setSelectedPage('')
                }}
                width={700}
                footer={null}
            >
                <Form
                    onFinish={handleIntegrationSubmit}
                    form={form}
                    layout='vertical'
                    className="integrations-form"
                >
                    <Title level={4} className="itc-form-title">Add User Interface</Title>
                    <Form.Item
                        label="Provide iframe code"
                        name="iframe"
                        className='integrations-form-item'
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your iframe code'
                            },
                            {
                                pattern: /<iframe\b[^>]*>(.*?)<\/iframe>/g,
                                message: 'Please enter a valid iframe code'
                            }   
                        ]}
                    >
                        <Input 
                            type="text" 
                            placeholder="e.g <iframe src='https://interfaces.zapier.com/embed/page/cm4nobuqo000314ba5b206zxf?' style='max-width: 900px; width: 100%; height: 500px;'></iframe>" 
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Select page to embed this interface"
                        name="page"
                        className='integrations-form-item'
                        rules={[
                            {
                                required: true,
                                message: 'Please select your preferred page to embed this interface'
                            }
                        ]}
                    >
                        <Radio.Group
                            onChange={(e) => setSelectedPage(e.target.value)}
                        >
                            <Space direction="vertical">
                                <Radio value="webpage">Web Page</Radio>
                                <Radio value="dashboard">Dashboard</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    { 
                        selectedPage &&
                        <Form.Item
                            label="Select the section to embed this interface"
                            name="pageLocation"
                            className='integrations-form-item'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select your preferred section to embed this interface'
                                }
                            ]}
                            extra={`The iframe will be embedded ${selectedPage === "dashboard" ? "in " : "below "} the selected section on the ${selectedPage}`}
                        >
                            <Radio.Group>
                                <Space direction="vertical">
                                    {
                                        pageSections.map((section) => (
                                            <Radio key={section} value={section}>{section}</Radio>
                                        ))
                                    }
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    }
                    <Form.Item
                        label="Select UI source"
                        name="source"
                        className='integrations-form-item'
                        rules={[
                            {
                                required: true,
                                message: 'Please select the source of this user interface'
                            }
                        ]}
                    >
                        <Select
                            placeholder="Select the source of your UI"
                            size="large"
                            options={
                                uiSourceOptions.map((item) => (
                                    {
                                        value: item.value,
                                        label: item.label
                                    }
                                ))
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Provide an helper text (optional)"
                        name="description"
                        className='integrations-form-item'
                    >
                        <Input 
                            type="text" 
                            placeholder="Enter text here..." 
                            size="large"
                            maxLength={250}
                        />
                    </Form.Item>
                    <Form.Item className='integrations-form-item mt-4 d-flex justify-content-end'>
                        <Space>
                            <Button 
                                className='itc-next-btn' 
                                type='secondary'
                                onClick={() => {
                                    setOpenInterfaceModal(false)
                                    setSelectedIntegration(null)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button 
                                className='itc-next-btn' 
                                type='primary' 
                                htmlType='submit'
                                loading={submittingIntegration}
                            >
                                { selectedIntegration ? 'Update' : 'Add'}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <ActionModal
                visible={deleteModal}
                handleCancel={() => {
                    setDeleteModal(false)
                    form.resetFields()
                    setSelectedPage('')
                }}
                handleClick={() => deleteIntegration()}
                message={"This action will permanently delete the User Interface. Please make sure you want to delete the item before proceeding."}
                loading={deletingIntegration}
                deleteText={"Delete"}
            />
        </div>
    )
}

export default ExternalIntegrationsNew