import React, { useEffect, useState } from 'react'
import { Button, notification, Typography } from 'antd'
import { TestimonialForm } from '../../create-itc/components/testimonials'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import TestimonialList from '../../create-itc/components/testimonial-list'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'
import HiddenBanner from './hidden-banner'

const { Title, Paragraph } = Typography

const ProfileTestimonials = ({itcId, itcDetails, getItcDetails, token}) => {
    const [showAddedTestimonial, setShowAddedTestimonial] = useState(false)
    const [testimonials, setTestimonials] = useState([])
    const [loadingTestimonials, setLoadingTestimonials] = useState(false)
    const [editTestimonialData, setEditTestimonialData] = useState(null)
    const [submittingDetails, setSubmittingDetails] = useState(false)

    const fetchTestimonials = () => {
        setLoadingTestimonials(true)
        ApiBaseHelper.get({
            url: `itc/testimonial/all?itc=${itcId}`, 
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setTestimonials(res?.data?.data)
            setLoadingTestimonials(false)
        }).catch((error) => {
            console.error(error)
            setLoadingTestimonials(false)
        })       
    }


    // Enable testimonails
    const handleEnableTestimonial = () => {
        setSubmittingDetails(true)
        const isIncludeSection = itcDetails?.custom_data?.hiddenSections?.split(",").includes('testimonials')
        ApiBaseHelper.put({
            url: `itc/${itcId}`,	
            headers: {
                Authorization: 'Bearer ' + token,
            },
            payload: {
                Company_Name: itcDetails?.Company_Name,
                Hidden_Sections: isIncludeSection 
                    ? itcDetails?.custom_data.hiddenSections?.split(",").filter((section) => section !== 'testimonials').filter(Boolean).join(",") 
                    : [...(itcDetails?.custom_data.hiddenSections?.split(",").filter(Boolean) || []), 'testimonials'].join(",")
            }
        }).then((res) => {
            setSubmittingDetails(false)
            notification.success({
                message: 'TecMe',
                description: `Testimonial section is now ${isIncludeSection ? 'visible' : 'hidden'} on webpage`
            })
            getItcDetails()
        }).catch((err) => {
            console.error(err)
            setSubmittingDetails(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occurred'
            })
        })
    }



    useEffect(() => {
        fetchTestimonials()
    }, [])

    useEffect(() => {
        fetchTestimonials()
    }, [showAddedTestimonial])

    return (
        <div>
           {
                (!showAddedTestimonial && testimonials.length > 0) &&
                <HiddenBanner 
                    text={itcDetails?.custom_data?.hiddenSections?.split(",").includes('testimonials') ? "The testimonial section will not be displayed on your webpage because you've hidden it." : "If you don't want the testimonial section displayed on your webpage, you can hide it"}
                    hidden={!!itcDetails?.custom_data?.hiddenSections?.split(",").includes('testimonials')}
                    loading={submittingDetails} 
                    handleClick={handleEnableTestimonial}
                />
            }
            {
                showAddedTestimonial ?
                <div className="profile-testimonial-form-ctn">
                    <TestimonialForm 
                        setShowAddedTestimonial={setShowAddedTestimonial}
                        isFromItcProfile={true}
                        fetchTestimonials={fetchTestimonials}
                        editTestimonialData={editTestimonialData}
                        setEditTestimonialData={setEditTestimonialData}
                />
                </div> :
                <>
                    <Title level={4} className="itc-form-title">Testimonials</Title>
                    <TestimonialList
                        testimonials={testimonials} 
                        setShowAddedTestimonial={setShowAddedTestimonial}
                        fetchTestimonials={fetchTestimonials}
                        isFromItcProfile={true}
                        loadingTestimonials={loadingTestimonials}
                        setEditTestimonialData={setEditTestimonialData}
                    />
                </>
            }
        </div>
    )
}

export default ProfileTestimonials