import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Form, Input, Space, Tooltip, Typography, notification } from 'antd'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { InfoCircleOutlined } from '@ant-design/icons'

const { Title, Paragraph } = Typography

const Membership = ({handleNextClick, companyDetails, businessLocation, itcId, setItcId, logoFile, setCompanyDetails, locationId}) => {

    return (
        <div className="itc-membership-section">
            <div className="itc-membership-ctn">
                <MembershipForm 
                    handleNextClick={handleNextClick}
                    companyDetails={companyDetails}
                    setCompanyDetails={setCompanyDetails}
                    businessLocation={businessLocation}
                    itcId={itcId}
                    setItcId={setItcId}
                    logoFile={logoFile}
                    locationId={locationId}
                />
            </div>
        </div>
    )
}


export const MembershipForm = ({handleNextClick, companyDetails, setCompanyDetails, itcId, setItcId, logoFile, businessLocation, locationId, isFromItcProfile=false}) => {
    const tech = useSelector(state => state.tech)
    const [form] = Form.useForm();
    const [membershipType, setMembershipType] = useState('one-time')
    const param = useParams()
    const [submittingDetails, setSubmittingDetails] = useState(false)
    const [freeMembership, setFreeMembership] = useState(true)

    const handleMembershipSubmit = (values) => {
        if(isFromItcProfile){
            setSubmittingDetails(true)
            ApiBaseHelper.put({
                url: `itc/${param.id}`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload: {
                    Membership_Fee: freeMembership ? 0 :  values.membershipFee,
                    Fee_Type: freeMembership ? "free" :  membershipType
                }
            }).then((res) => {
                setSubmittingDetails(false)
                notification.success({
                    message: 'TecMe',
                    description: 'Membership fee updated successfully'
                })
            }).catch((err) => {
                setSubmittingDetails(false)
                console.log(err)
                notification.error({
                    message: 'TecMe',
                    description: 'An error occurred while updating membership fee'
                })
            })
        }else{
            setCompanyDetails({
                ...companyDetails,
                membership_fee: freeMembership ? 0 : values.membershipFee,
                membership_type: freeMembership ? "free" : membershipType
            })
            handleCompanyDetailsSubmit(values)
        }
    }

    const handleCompanyDetailsSubmit = (values) => {
        setSubmittingDetails(true)
        if(itcId){
            ApiBaseHelper.put({
                url: `itc/${itcId}`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload: {
                    Company_Name: companyDetails?.name,
                    isProject: companyDetails?.is_project ? "1" : "0",
                    Description:  companyDetails?.description,
                    Brand_Color: companyDetails?.brand_color?.replace('#', ''),    
                    Subdomain: companyDetails?.subdomain,
                    Years_Experience: companyDetails?.experience_years,
                    Fee_Type: freeMembership ? "free" :  membershipType,
                    Membership_Fee: freeMembership ? 0 :  values.membershipFee,
                    LinkedIn_URL: companyDetails?.linkedin_url,
                    Facebook_URL: companyDetails?.facebook_url,
                    Youtube_URL: companyDetails?.youtube_url,
                    Twitter_URL:  companyDetails?.twitter_url,
                    Tiktok_URL: companyDetails?.tiktok_url,
                    Instagram_URL: companyDetails?.instagram_url,
                    Website_URL: companyDetails?.website_url,
                    Project_Links: companyDetails?.project_links,
                    Crm_Form: companyDetails?.crm_form,
                    Kanban_Board: companyDetails?.kanban_board,
                    Hidden_Sections: companyDetails?.hidden_sections,
                    Location: locationId,
                    Brand: !logoFile && null 
                }
            }).then((res) => {
                if(logoFile){
                    handleFileSubmit(itcId)
                    return
                }
                setSubmittingDetails(false)
                handleNextClick()
            }).catch((err) => {
                setSubmittingDetails(false)
                console.log(err)
            })
            return
        }
        // handleNextClick()
        ApiBaseHelper.post({
            url: `itc`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: {
                Active:"true",
                Company_Name: companyDetails?.name,
                isProject: companyDetails?.is_project ? "1" : "0",
                Description:  companyDetails?.description,
                Brand_Color: companyDetails?.brand_color?.replace('#', ''),    
                Subdomain: companyDetails?.subdomain?.toLowerCase(),
                Years_Experience: companyDetails?.experience_years,
                Fee_Type: freeMembership ? "free" :  membershipType,
                Membership_Fee: freeMembership ? 0 : values.membershipFee,
                LinkedIn_URL: companyDetails?.linkedin_url,
                Facebook_URL: companyDetails?.facebook_url,
                Youtube_URL: companyDetails?.youtube_url,
                Twitter_URL:  companyDetails?.twitter_url,
                Tiktok_URL: companyDetails?.tiktok_url,
                Instagram_URL: companyDetails?.instagram_url,
                Website_URL: companyDetails?.website_url,
                Project_Links: companyDetails?.project_links,
                CRM_Form: companyDetails?.crm_form,
                Kanban_Board: companyDetails?.kanban_board,
                Hidden_Sections: companyDetails?.hidden_sections,
                Location: locationId
            }
        }).then((res) => {
            if(res){
                if(logoFile){
                    handleFileSubmit(res?.data?.data?.id)
                    setItcId(res?.data?.data?.id)
                    return
                }
                setItcId(res?.data?.data?.id)
                handleNextClick()
            }
            setSubmittingDetails(false)
        }).catch((err) => {
            setSubmittingDetails(false)
            console.log(err)
        })
    }

    const handleFileSubmit = (id) => {
        const payload = new FormData();
        payload.append('file', logoFile)
        ApiBaseHelper.post({
            url: `itc/logo/${id}`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: payload
        }).then((res) => {
            setSubmittingDetails(false)
            handleNextClick()
        }).catch((err) => {
            setSubmittingDetails(false)
            console.log(err)
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            membershipFee: companyDetails?.membership_fee
        })
        setMembershipType(companyDetails?.membership_type)
    }, [])

    useEffect(() => {
        if(companyDetails?.membership_fee === 0 && companyDetails?.fee_type === "free"){
            setFreeMembership(true)
        }
    }, [companyDetails])

    return(
        <div className="itc-membership-form-ctn">
            <Title level={4} className="itc-form-title">Select membership fee type</Title>
            <Paragraph className='info-details-text'><InfoCircleOutlined style={{ marginRight:3, position:"relative", top:2}}/> Paid membership coming soon!</Paragraph>
            <Space className="select-membership-btn-ctn" size={12}>
                <Button 
                type='secondary' 
                className={`select-membership-btn ${membershipType === 'one-time' ? 'active' : ''}`}
                onClick={() => setMembershipType('one-time')}
                disabled={freeMembership}
                >
                    One Time
                </Button>
                <Button 
                    type='secondary'
                    className={`select-membership-btn ${membershipType === 'monthly' ? 'active' : ''}`}
                    onClick={() => setMembershipType('monthly')}
                    disabled={freeMembership}
                >
                    Monthly Recurring
                </Button>
            </Space>
            <div>
            <Tooltip placement="top" title={"Only free membership option is available currently"}>
                <Checkbox 
                    className="itc-membership-checkbox"
                    // onChange={(e) => {
                    //     if(e.target.checked){
                    //         setFreeMembership(true)
                    //         setMembershipType("")
                    //     }else{
                    //         setFreeMembership(false)
                    //         setMembershipType("one-time")
                    //     }
                    // }}
                    checked={freeMembership}
                    // disabled={true}
                >   
                    Offer free membership to team members
                </Checkbox>
            </Tooltip>
            </div>
            <Form
                onFinish={handleMembershipSubmit}
                form={form}
                layout='vertical'
                className="itc-membership-form"
            >
                {
                    !freeMembership &&
                    <Form.Item
                        name="membershipFee"  
                        label={ membershipType === "one-time" ? "Set one-time fee" : "Set monthly recurring fee"} 
                        className='itc-membership-form-label-item'
                        extra={membershipType === "one-time" ? "Technician will be charged one-time membership fee at the time of joining your ITSC" : "Technicians will incur a membership fee upon joining your ITSC, which will continue on a monthly basis thereafter"}
                        rules={[
                            { required: true, message: 'Please enter membership fee' }, 
                            { pattern: /^[1-9]\d*$/, message: 'Please enter a valid membership fee' }
                        ]}
                    >
                        <Input 
                            type="text" 
                            placeholder="250" 
                            size="large"
                            addonBefore="$"
                        />
                    </Form.Item>
                }
                {
                    !isFromItcProfile ?
                    <Form.Item className='itc-form-label-item'>
                        <Button className='itc-next-btn' type='primary' htmlType='submit'
                            loading={submittingDetails}
                        >Next</Button>
                    </Form.Item> :
                    <Form.Item className='itc-form-label-item'>
                        <Button 
                            className='itc-next-btn' 
                            type='primary' 
                            htmlType='submit'
                            loading={submittingDetails}
                        >Update</Button>
                    </Form.Item>
                }
            </Form>
        </div>
    )
}

export default Membership