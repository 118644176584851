
import { Avatar, Button, Card, List, Space, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import RightArrow from '../../../../../../assets/images/right-arrow.svg'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../../../utils/routes'

const {Title, Paragraph} = Typography

const ActiveClients = ({contractData}) => {
    const [activeClients, setActiveClients] = useState([])
    const history = useHistory()

    console.log(contractData)

    useEffect(() => {
        const activeClients = []
        const seen = new Set();
        for(const obj of contractData){
            if(!seen.has(obj.Customer_Org.id)){
                seen.add(obj.Customer_Org.id)
                activeClients.push(obj.Customer_Org)
            }
        }
        setActiveClients(activeClients)
    }, [contractData])

    console.log(activeClients)

    return (
        <div className="active-user-content">
            <div className="active-user-heading">
                <div> 
                    <Title level={4} style={{marginBottom:20, color:"#000"}}>Active Clients</Title>
                    <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>Access cybersecurity dashboards for thr organizations you have active contracts with</Paragraph> 
                </div>
            </div>
            <div className="client-list">
                <List
                    grid={{ gutter: 16,  xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 4,
                        xl: 4,
                        xxl: 4, 
                    }}
                    pagination={{
                        pageSize: 8,
                    }}
                    dataSource={activeClients}
                    renderItem={item => (
                        <List.Item>
                             <div className='client-card'>
                                <Space>
                                    <Avatar size={44} src={item?.Logo}>
                                        {item?.Name?.[0]?.toUpperCase()}
                                    </Avatar>
                                    <div>
                                        <Title className='m-0' level={5}>{item?.Name}</Title>
                                        <Paragraph className='m-0' style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>{item?.HIPAA_Contact_Name}</Paragraph>
                                    </div>
                                </Space>
                                <Space align='center'>
                                    <Button
                                        className='borderless-btn'
                                        onClick={() => 
                                            history.push({
                                                pathname: ROUTES.CYBERSECURITY_DETAILS.replace(':hipaa_id', item?.id
                                                ),
                                                state: item
                                            })
                                        }
                                    >
                                        View Cybersecurity Dashboards
                                    </Button>
                                    <img src={RightArrow} style={{marginTop:-2}} alt="right arrow" />
                                </Space>
                            </div>
                        </List.Item>
                    )}
                />
            </div>
        </div>
    )
}

export default ActiveClients