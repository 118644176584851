import React, { useEffect, useState } from 'react'
import './assets/css/styles.scss'
import { META_DESCRIPTION } from '../../../../../utils/constants'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import { MetaTags } from 'react-meta-tags'
import ROUTES from '../../../../../utils/routes'
import { Avatar, Button, Layout, Space, Tooltip, Typography } from 'antd'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment'
import NewUser from './components/new-user'
import HelpSticker from './components/help-sticker'
import { useSelector } from 'react-redux'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import ActiveUser from './components/active-user'
import { useHistory } from 'react-router-dom'
import PageFallback from '../../../../components/page-fallback'
import DashboardLoader from './components/dashboard-loader'
import TechConnectModal from '../../../../components/modals/tech-connect-modal'
import { capitalizeFirstLetter } from '../../../../../utils/helper'
import RightArrow from '../../../../../assets/images/right-arrow.svg'


const { Content } = Layout
const {Title, Paragraph, Text} = Typography

const UserDashboard = () => {

    const client = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const techReserve = useSelector(state => state.techReserve)
    const [teamMembersList, setTeamMembersList] = useState([])
    const [ loadingContracts, setLoadingContracts ] = useState(false)
    const [ contractData, setContractData ] = useState([])
    const [ ticketData, setTicketData ] = useState([])
    const history = useHistory()

    const authUser = client || tech
console.log(client)
    const fetchContracts = () => {
        setLoadingContracts(true)
        ApiBaseHelper.get({
            url: `contract?role=${tech ? 'technician' : 'customer'}`, headers: {
                Authorization: 'Bearer ' + authUser?.token
            }
        }).then((res) => {
            const sortedContractData =  [...res?.data.data.filter((item) => item.Status === 'Active')]
            sortedContractData?.sort((a, b) => {
                const dateA = new Date(a.Active_Date_Time);
                const dateB = new Date(b.Active_Date_Time);
                return dateB - dateA;
            });
            setContractData(sortedContractData)
            setLoadingContracts(false)
        }).catch((error) => {
            console.error(error)
            setLoadingContracts(false)
        })       
    }

    const fetchTickets = () => {
        ApiBaseHelper.get({
            url: `ticket?role=${tech ? 'technician' : 'customer'}`, headers: {
                Authorization: 'Bearer ' + authUser?.token
            }
        }).then((res) => {
            const sortedTicketData =  [...res?.data.data.filter((item) => item.Status === 'Open')]
            sortedTicketData?.sort((a, b) => {
                const dateA = new Date(a.Active_Date_Time);
                const dateB = new Date(b.Active_Date_Time);
                return dateB - dateA;
            });
            setTicketData(sortedTicketData)
        }).catch((error) => {
            console.error(error)
        })       
    }
    
    const getTeamMembersList = () => {
        ApiBaseHelper.get({
            url: 'user-management/users',
            headers: {
                Authorization: 'Bearer ' + client?.token,
            },
        }).then((res) => {
            setTeamMembersList(res?.data?.data?.data.filter((item) => item?.Status === 'Active'))
        })
    }

    useEffect(() => {
        getTeamMembersList()
        fetchContracts()
        fetchTickets()
        window.scrollTo(0, 0)
    }, [])


     // Fetch contracts when user changes view from customer to technician or vice versa
     useEffect(() => {
        if(client || tech){
            getTeamMembersList()
            fetchContracts()
            fetchTickets()
            window.scrollTo(0, 0)
        }
    }, [client, tech])

    return (
        <>
            <MetaTags>
                <title>Dashboard | TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow"/>
            </MetaTags>
            <Content className="main">
                <div className="page">
                    <div style={{minHeight: '800px'}}>
                        <PageHeaderWrapper
                            className="tech-skill-page-breadcrumb-header"
                            routes={[
                                {
                                    breadcrumbName: 'Dashboard',
                                },
                            ]}
                        />
                        <div className='auth-container'>
                            <div className='auth-page-ctn'>
                                {
                                    loadingContracts ? 
                                    <DashboardLoader /> :
                                    <div className="dashboard-ctn">
                                        <div className="dashboard-heading-section">
                                            <div className="dashboard-welcome-section">
                                                <Title level={3} style={{marginBottom:8, color:"#000"}}>Welcome to {
                                                    (client && client?.organization?.Name) ? capitalizeFirstLetter(client?.organization?.Name) :  'TecMe'
                                                }, {authUser?.firstname}</Title>
                                                <Paragraph style={{color:"rgba(0, 0, 0, 0.65)"}}>Here you can access and track all your company IT needs.</Paragraph>
                                            </div>
                                            {
                                                client &&
                                                <div className="dashboard-cta-section">
                                                    <Space size={24} wrap>
                                                        <Button type='primary' icon={<PlusOutlined />} style={{height:40,fontSize:16, width:203}} onClick={() => history.push(ROUTES.TEAM_MANAGEMENT)}>Invite Team Members</Button>
                                                        <Button type='secondary' style={{height:40, fontSize:16, width:203}}onClick={() => history.push(ROUTES.DISCOVERY_EXPERIENCE_SEARCH)}>Browse Technicians</Button>
                                                    </Space>
                                                    <Space align='center'>
                                                        <Button
                                                            className='borderless-btn'
                                                            onClick={() =>
                                                                history.push({
                                                                    pathname: ROUTES.CYBERSECURITY_DETAILS.replace(':hipaa_id', client?.orgId
                                                                    ),
                                                                    state: {
                                                                        Contact_Image: client?.imageUrl,
                                                                        Name: client?.organization.Name,
                                                                        HIPAA_Contact_Name: `${client?.firstname} ${client?.lastname}`,
                                                                        HIPAA_Primary_Contact: client?.id,
                                                                        Logo: client?.organization?.Logo,
                                                                        id: client?.orgId
                                                                    }
                                                                })
                                                            }
                                                        >
                                                            View Cybersecurity Dashboards
                                                        </Button>
                                                        <img src={RightArrow} style={{marginTop:-2}} alt="right arrow" />
                                                    </Space>
                                                </div>   
                                            }
                                        </div>
                                        <div className="dashboard-content">
                                            <NewUser 
                                                contractData={contractData}
                                                ticketData={ticketData}
                                                client={client}
                                                tech={tech}
                                                loadingContracts={loadingContracts}
                                            />
                                            <ActiveUser 
                                                contractData={contractData}
                                                ticketData={ticketData}
                                                loadingContracts={loadingContracts}
                                                client={client}
                                                tech={tech}
                                                isFromDashboard={true}
                                            />
                                        </div>
                                    </div>
                                }     
                            </div>
                        </div>
                    </div>
                </div>
               { 
                 (contractData.length === 0 && !loadingContracts) &&  <HelpSticker />
               }
                <TechConnectModal />
            </Content>
        </>
    )
}

export default UserDashboard