import React from 'react'
import ExternalIntegrationsNew from '../../create-itc/components/external-integrations-new'

const ProfileExternalIntegrations = ({ itcId }) => {
  return (
    <div>
        <ExternalIntegrationsNew 
            isFromItcProfile={true}
            itcId={itcId}
        />
    </div>
  )
}

export default ProfileExternalIntegrations