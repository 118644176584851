import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Radio, Select, Skeleton, Space, Tag, Typography, message, notification } from 'antd'
import { useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { AddedItemCard, AddedItemMessage } from './added-item';
import BackIcon from '../assets/images/back-icon.svg'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';
import { useParams } from 'react-router-dom';
import SpecializationList from './specialization-list';
import { getSpecializationTitle } from '../../../../common/private/job-post/utils/helper';
import { generateAiResponse } from '../../../../../../utils/openai-helper';

const {Title, Paragraph} = Typography
const { Option } = Select;
const { TextArea } = Input;

const ItcSpecializations = ({handleNextClick, setShowBackBtn, itcId}) => {
    const tech = useSelector(state => state.tech)
    const [showAddedSpecialization, setShowAddedSpecialization] = useState(false)
    const [loadingSpecializations, setLoadingSpecializations] = useState(false)
    const [specializations, setSpecializations] = useState([])
    const [editSpecializationData, setEditSpecializationData] = useState(null)
   
    const fetchSpecializations = () => {
        setLoadingSpecializations(true)
        ApiBaseHelper.get({
            url: `itc/specialization/all?itc=${itcId}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setSpecializations(res?.data?.data)
            setLoadingSpecializations(false)
        }).catch((error) => {
            console.error(error)
            setLoadingSpecializations(false)
        })       
    }
   
    useEffect(() => {
        setShowBackBtn(showAddedSpecialization ? false : true)
    }, [showAddedSpecialization])

    useEffect(() => {
        if(specializations?.length > 0){
            setShowAddedSpecialization(true)
        }   
    }, [specializations])

    useEffect(() => {
        fetchSpecializations()
    }, [])

    return (
        <div className="itc-specialization-section">
            <div className="itc-specialization-ctn">
                {
                    showAddedSpecialization ? 
                    <AddedSpecialization 
                        handleNextClick={handleNextClick} 
                        showAddedSpecialization={showAddedSpecialization}
                        setShowAddedSpecialization={setShowAddedSpecialization}
                        setShowBackBtn={setShowBackBtn}
                        specializations={specializations}
                        setSpecializations={setSpecializations}
                        itcId={itcId}
                        fetchSpecializations={fetchSpecializations}
                        loadingSpecializations={loadingSpecializations}
                        setEditSpecializationData={setEditSpecializationData}
                    /> :
                    <div className="specialization-form-ctn">
                        <Title level={4} className="itc-form-title">Add New Specialization</Title>
                        <SpecializationForm 
                            handleNextClick={handleNextClick}
                            setShowAddedSpecialization={setShowAddedSpecialization}
                            showAddedSpecialization={showAddedSpecialization}
                            itcId={itcId}
                            fetchSpecializations={fetchSpecializations}
                            editSpecializationData={editSpecializationData}
                            setEditSpecializationData={setEditSpecializationData}
                            specializations={specializations}
                        />
                    </div> 
                }       
            </div>
        </div>
    )
}

export const SpecializationForm = ({handleNextClick, fetchSpecializations, setShowAddedSpecialization, itcId, editSpecializationData, setEditSpecializationData, specializations, showAddedSpecialization, isFromItcProfile = false}) => {
    const tech = useSelector(state => state.tech)
    const [form] = Form.useForm();
    const generalSkills = useSelector((state) => state.generalSkillsGlobal)
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const [workType, setWorkType] = useState(null)
    const [suggestedSkills, setSuggestedSkills] = useState([])
    const [submittingSpecialization, setSubmittingSpecialization] = useState(false)
    const [generatedSkills, setGeneratedSkills] = useState([])
    const [loadingGeneratedSkills, setLoadingGeneratedSkills] = useState(false)
    const params = useParams()
     
    const values = Form.useWatch([], form)
    
    // Check if all required fields are filled
    const isDisabled = !selectedCategoryId || !values?.specialization_title || !values?.specialization_description 

    const getSelectectedSpecialization = generalSkills?.find((item) => item.id === (selectedCategoryId))

    const handleSpecializationSubmit = (values) => {
        setSubmittingSpecialization(true)
        if(editSpecializationData){
            ApiBaseHelper.put({
                url: `itc/specialization/${editSpecializationData.id}`, 
                payload: {
                    Active: 'true', 
                    Category: selectedCategoryId,
                    Title: values.specialization_title,
                    Introduction: values.specialization_description, 
                    Owner_Contact: tech.id, 
                    Skills: values.specialization_skills.join("|"),
                    Specialization: true,
                    Work_Type: workType || editSpecializationData.Work_Type,
                },
                headers: {
                    Authorization: 'Bearer ' + tech?.token,
                },
            }).then(async (res) => {
                if(res){
                    notification['success']({
                        message:"TecMe",
                        description: "Specialization updated successfully" 
                    })
                    setSubmittingSpecialization(false)
                    setEditSpecializationData(null)
                    await fetchSpecializations()
                    setShowAddedSpecialization(isFromItcProfile ? false : true)
                }
            }).catch((error) => {
                console.log(error)
                setSubmittingSpecialization(false)
                notification['error']({
                    message: "TecMe",
                    description:"An Error Occurred"
                })
            })
        }else{
            ApiBaseHelper.post({
                url: `itc/specialization`, 
                payload: {
                    Active: 'true', 
                    Category: selectedCategoryId,
                    Title: values.specialization_title,
                    Introduction: values.specialization_description, 
                    Owner_Contact: tech.id, 
                    Skills: values.specialization_skills.join("|"),
                    Specialization: true,
                    Work_Type: workType,
                    ITC: isFromItcProfile ? params.id : itcId
                },
                headers: {
                    Authorization: 'Bearer ' + tech?.token,
                },
            }).then(async (res) => {
                if(res){
                    notification['success']({
                        message:"TecMe",
                        description: "Specialization created successfully" 
                    })
                    setSubmittingSpecialization(false)
                    await fetchSpecializations()
                    setShowAddedSpecialization(isFromItcProfile ? false : true)
                }
            }).catch((error) => {
                console.log(error)
                setSubmittingSpecialization(false)
                notification['error']({
                    message: "TecMe",
                    description:"An Error Occurred"
                })
            })
        }
    }

    function handleChange(value) {
        setSelectedCategoryId(value)
    }
   
     function handleSearch(value) {
        console.log(`Searched ${value}`);
    }

    const getGeneratedSkills = async () => {
        setLoadingGeneratedSkills(true)

        const prompt = `List the essential skills for a specialization in \"${getSpecializationTitle(generalSkills, selectedCategoryId)}\". Separated by comma. A maximum of 10 skills and remove the noun adjunct (like "understanding of", "knowledge of", etc) and just give only the skills`;

        const response = await generateAiResponse(prompt, tech?.token)
        if(response){
            // Extract and format the IT skills from the response
            const skillsList = response?.content?.split(', ')
            setGeneratedSkills(skillsList);
            setLoadingGeneratedSkills(false)
        }  
        setLoadingGeneratedSkills(false)
    };
    // useEffect(() => {
    //     setSuggestedSkills(getSelectectedSpecialization?.other_skills)
    // }, [selectedCategoryId, getSelectectedSpecialization])

    useEffect(() => {
        if(selectedCategoryId){
            getGeneratedSkills()
        }
    }, [selectedCategoryId])

    useEffect(() => {
        if(editSpecializationData){
            form.setFieldsValue({
                specialization_category: editSpecializationData?.Category,
                specialization_title: editSpecializationData?.Title,
                specialization_description: editSpecializationData?.Introduction,
                specialization_work_type: editSpecializationData?.Work_Type,
                specialization_skills: editSpecializationData?.Skills.split("|")
            })
            setSelectedCategoryId(editSpecializationData?.Category)
            setSuggestedSkills(editSpecializationData?.Skills.split("|"))
        }
    }, [editSpecializationData])

    return(
        <Form
            onFinish={handleSpecializationSubmit}
            form={form}
            layout='vertical'
            className="itc-specialization-form"
        >
            <Form.Item
                name="specialization_category"
                label="Select a category to begin"
                className='itc-form-label-item itc-specialization-category'
                rules={[{ required: true, message: 'Please select a category' }]}
            >
                <Select
                    popupClassName='create-specialization-select'
                    showSearch
                    listHeight={264}
                    placeholder="Please select"
                    optionFilterProp="children"
                    onChange={handleChange}
                    onSearch={handleSearch}
                    showArrow={false}
                    virtual={false}
                    size='large'
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    >
                        {
                            generalSkills?.length && generalSkills?.map((skill, index) => {
                                return (
                                    <Option className={skill.id === skill.parent_id && 'title-option'} value={skill.id}>{skill.Title}</Option>
                                )
                            })
                        }
                </Select>
            </Form.Item>
            <Form.Item
                name="specialization_title"
                label="Specialization Title"
                className='itc-form-label-item itc-specialization-category'
                rules={[
                    { required: true, message: 'Please enter title'}, 
                ]}
            >
                <Input
                    placeholder='E.g. Cybersecurity expert'
                    size='large'
                />
            </Form.Item>
            <div
                className='itc-form-label-item'
            >
                <Paragraph level={4} style={{fontSize:16, fontWeight:500, marginBottom:4}}>Specialization Description</Paragraph>
                <Paragraph style={{fontSize:16, color:"rgba(0, 0, 0, 0.6)"}}>Describe specialization in detail and help employers understand your capabilities.</Paragraph>
                <Form.Item
                    name="specialization_description"
                    rules={[
                        { required: true, message: 'Please enter description'},
                        { min: 200, message: 'Minimum of 200 characters allowed'},
                        { max: 1500, message: 'Maximum of 1500 characters allowed.' }, 
                    ]}
                >
                    <TextArea
                        showCount 
                        maxLength={1500} 
                        rows={5} 
                        placeholder={"• minimum 200 characters \n• describe your expertise \n• mention your differentiations"}/>
                </Form.Item>
            </div>
            <Form.Item
                label="Work Type"
                name="specialization_work_type"
                className='itc-form-label-item'
                rules={[
                    { required: true, message: 'Please select work type'},
                ]}
            >
                <Radio.Group onChange={(e) => setWorkType(e.target.value)}>
                    <Space direction="vertical">
                        <Radio value="Both">Remote & On-site</Radio>
                        <Radio value="Remote">Remote</Radio>
                        <Radio value="On-Site">On-site</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
            <div className="specialization-skills-section">
                <Title level={4} style={{margin:0}}>Skills</Title>
                <Paragraph style={{fontSize:16, color:"rgba(0, 0, 0, 0.6)"}}>Selecting relevant skills can make your profile stand out to potential clients, helping you find jobs that match your expertise and interests while building a successful career.</Paragraph>
                <div className='skills-input-tags'>
                    <Form.Item
                        name="specialization_skills"
                        className='mb-4  itc-form-label-item itc-specialization-skill'
                        rules={[
                            { required: true, message: 'Please add a skill'}, 
                        ]}
                    >
                        <Select 
                            mode="tags"
                            placeholder="Add skills"
                            popupClassName='add-skills-select'
                            onChange={() => {}}
                            // defaultValue={suggestedSkills}
                            listHeight={264}
                            virtual={false}
                            size='large'
                            onDeselect={(value) => {
                                setSuggestedSkills(suggestedSkills.filter((ele) => ele !== value))
                            }}
                            onSelect={(value) => {
                                if(suggestedSkills.includes(value)) return
                                setSuggestedSkills([...suggestedSkills, value])
                                form.setFieldsValue({
                                    specialization_skills: [...suggestedSkills, value]
                                })
                            }}
                            options={ 
                                (generatedSkills || getSelectectedSpecialization?.other_skills).map((ele) => {
                                    return {
                                        label: ele,
                                        value: ele 
                                    }
                                }) 
                            }
                        />
                    </Form.Item>
                    <div className='mb-4'>
                        { 
                            (!!generatedSkills.length || !!getSelectectedSpecialization?.other_skills.length) &&
                            <Paragraph style={{marginBottom:5, fontSize:14, fontWeight:500, color:"color: rgba(0, 0, 0, 0.85)"}}>Suggested:</Paragraph>
                        }
                        {
                            loadingGeneratedSkills ?
                            <Space style={{width:"100%"}} wrap>
                                {
                                    [1,2,3,4,5,6].map((ele) => <Skeleton.Button active size='large' shape='square' style={{width:150}}/>)
                                }
                            </Space>
                            :
                            (generatedSkills || getSelectectedSpecialization?.other_skills).map((ele) => <Tag
                                className='custom-ant-tag cursor-pointer mb-2'
                                onClick={() => {
                                    if(suggestedSkills.includes(ele)) return
                                    setSuggestedSkills([...suggestedSkills, ele])
                                    form.setFieldsValue({
                                        specialization_skills: [...suggestedSkills, ele]
                                    })
                                }}
                                >
                                    {ele}
                                </Tag>)
                        }
                    </div>
                </div>
            </div>
           { 
                !isFromItcProfile ?
                <Form.Item>
                    <Space>
                        <Button 
                            className='itc-next-btn' 
                            type='primary' 
                            htmlType='submit'
                            loading={submittingSpecialization}
                            disabled={!showAddedSpecialization && isDisabled}
                        >
                            Next
                        </Button>
                        {
                            !showAddedSpecialization &&
                            <Button 
                                className='itc-next-btn' 
                                type='secondary'
                                onClick={() => handleNextClick()}
                            >
                                Skip
                            </Button>
                        }
                    </Space>
                </Form.Item> :
                <Form.Item>
                    <Space>
                        <Button 
                            className='itc-next-btn' 
                            type='secondary'
                            onClick={() => {
                                setShowAddedSpecialization(false)
                                setEditSpecializationData(null)
                            }}
                        >
                            Back
                        </Button>
                        <Button 
                            className='itc-next-btn' 
                            type='primary' 
                            htmlType='submit'
                            loading={submittingSpecialization}
                        >
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            }
        </Form>
    )
}

const AddedSpecialization = ({handleNextClick, setShowBackBtn, showAddedSpecialization, setShowAddedSpecialization, specializations, loadingSpecializations,  fetchSpecializations, setEditSpecializationData}) => {

    useEffect(() => {
        setShowBackBtn(showAddedSpecialization ? false : true)
    }, [showAddedSpecialization])

    return(
        <div className='added-specialization-section'>
            <Space size={10} 
                onClick={() => {
                    setShowAddedSpecialization(false)
                }} 
                className='itc-back-btn'
            >
                <img src={BackIcon} alt="Back Icon" />
                <span>Go Back</span>
            </Space>
            <div className="added-specialization-ctn">
                {/* <AddedItemMessage message={"Specialization added successfully"} /> */}
                <Title level={4} className="itc-form-title">Specializations</Title>
                <SpecializationList 
                    specializations={specializations}
                    fetchSpecializations={fetchSpecializations}
                    setShowAddedSpecialization={setShowAddedSpecialization}
                    handleNextClick={handleNextClick}
                    loadingSpecializations={loadingSpecializations}
                    setEditSpecializationData={setEditSpecializationData}
                />
            </div>
        </div>
    )
}

export default ItcSpecializations